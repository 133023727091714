import React, { Component, Fragment } from "react";
import Footer from "../components/Footer/Footer";
import PageTop from "../components/PageTop/PageTop";
import TopNavigation from "../components/TopNavigation/TopNavigation";

export class AgbPage extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }

  render() {
    return (
      <Fragment>
        <TopNavigation title="AGB" />
        <PageTop pagetitle="AGB" />

        <Footer />
      </Fragment>
    );
  }
}

export default AgbPage;
