import React, { useEffect, useState, Fragment } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import Login from "../../asset/image/login.jpg";
import { Link, useHistory } from "react-router-dom";
import Loader from "../../components/Loader";
import Message from "../../components/Message";
import { useDispatch, useSelector } from "react-redux";

import { register } from "../../actions/userAction.js";

const RegisterForm = ({ location }) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [message, setMessage] = useState(null);
  const [confirmPassword, setConfirmPassword] = useState("");

  const history = useHistory();
  const dispatch = useDispatch();
  const userRegister = useSelector((state) => state.userRegister);
  const { loading, error, userInfo } = userRegister;

  useEffect(() => {
    if (userInfo != null) {
      history.push("/");
    }
  }, [history, userInfo]);

  const submitHandler = (e) => {
    e.preventDefault();
    if (password !== confirmPassword) {
      setMessage("Passwörter stimmen nicht überein.");
    } else {
      dispatch(register(name, email, password));
    }
  };

  return (
    <Fragment>
      <Container>
        {loading && <Loader />}
        <Row className="p-2 mt-5">
          <Col className="mt-2" md={12} lg={12} sm={12} xs={12}>
            <Row className="text-center">
              {message && <Message variant="danger"> {message}</Message>}
              {error && <Message variant="danger"> {error}</Message>}
              <Col
                className="d-flex justify-content-center"
                md={6}
                lg={6}
                sm={12}
                xs={12}
              >
                <Form className="onboardForm" onSubmit={submitHandler}>
                  <Form.Group controlId="name">
                    <Form.Control
                      className="form-control m-2"
                      type="name"
                      placeholder="Dein Name"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId="email">
                    <Form.Control
                      className="form-control m-2"
                      type="email"
                      placeholder="E-Mail"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId="password">
                    <Form.Control
                      className="form-control m-2"
                      type="password"
                      placeholder="Passwort"
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Form.Group controlId="confirmPassword">
                    <Form.Control
                      className="form-control m-2"
                      type="password"
                      placeholder="Passwort bestätigen"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    ></Form.Control>
                  </Form.Group>
                  <Button
                    type="submit"
                    className="btn btn-block m-2 site-btn-login"
                  >
                    {" "}
                    Registrieren{" "}
                  </Button>
                  <br></br> <br></br>
                  <hr />
                  <p>
                    {" "}
                    <b> Passwort vergessen? </b>
                    <Link to="/passwortvergessen">
                      <b> Passwort vergessen </b>{" "}
                    </Link>{" "}
                  </p>
                  <p>
                    {" "}
                    <b>Du hast bereits ein Konto? </b>
                    <Link to="/login">
                      <b> Login </b>{" "}
                    </Link>{" "}
                  </p>
                </Form>
              </Col>

              <Col className="p-0 Desktop m-0" md={6} lg={6} sm={6} xs={6}>
                <img className="onboardBanner" src={Login} alt="" />
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </Fragment>
  );
};

export default RegisterForm;
