import React, { Fragment, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import PageTop from "../../components/PageTop/PageTop";
import TopNavigation from "../../components/TopNavigation/TopNavigation";
import OrderList from "../../components/Admin/OrderList";
const OrderListScreen = ({ match, history }) => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <Fragment>
      <TopNavigation title="Bestellungen" />
      <PageTop pagetitle="Bestellungen" />
      <OrderList match={match} history={history} />
      <Footer />
    </Fragment>
  );
};

export default OrderListScreen;
