import React, { Fragment, useEffect } from "react";
import Footer from "../components/Footer/Footer";
import PageTop from "../components/PageTop/PageTop";
import TopNavigation from "../components/TopNavigation/TopNavigation";

import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faTrash } from "@fortawesome/free-solid-svg-icons";
import Message from "../components/Message";
import { Row, Col, ListGroup, Button, Card, Container } from "react-bootstrap";
import { addToCart, removeFromCart } from "../actions/cartAction";

const CartPage = ({ match, location, history }) => {
  const productId = match.params.id;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const qty = location.search ? Number(location.search.split("=")[1]) : 1;

  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const { cartItems } = cart;
  const removeFromCartHandler = (id) => {
    dispatch(removeFromCart(id));
  };

  const checkoutHandler = () => {
    history.push("/login?redirect=shipping");
  };

  useEffect(() => {
    if (productId) {
      dispatch(addToCart(productId, qty));
    }
  }, [dispatch, productId, qty]);
  return (
    <Fragment>
      <TopNavigation title="Kasse" />
      <PageTop pagetitle="Warenkorb" />
      <Container className="my-5">
        <Row>
          <Col md={8}>
            {cartItems.length === 0 ? (
              <Message>
                Du hast noch nichts im Warenkorb <Link to="/">Go Back</Link>
              </Message>
            ) : (
              <ListGroup variant="flush">
                {cartItems.map((item, index) => (
                  <ListGroup.Item key={index}>
                    <Row>
                      <Col md={8}>
                        <b>{item.title}</b>
                      </Col>
                      <Col md={2}>
                        {userInfo &&
                        userInfo.qrcnumber !== 0 &&
                        item.qrcprice !== "0"
                          ? `QRC Preis ${item.qrcprice} €`
                          : `${item.price} €`}
                      </Col>
                      <Col md={0}></Col>
                      <Col md={1}>
                        <Button
                          type="button"
                          variant="light"
                          onClick={() => removeFromCartHandler(item.peergroup)}
                        >
                          <FontAwesomeIcon
                            icon={faTrash}
                            size="1x"
                            style={{ color: "#f00" }}
                          />
                        </Button>
                      </Col>
                    </Row>
                    <Row>
                      <Col> {item?.shortdescription.substr(0, 50) + "..."}</Col>
                    </Row>
                  </ListGroup.Item>
                ))}
              </ListGroup>
            )}
          </Col>
          <Col md={4}>
            <Card>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h2>Gesamtsumme</h2>
                  <Row className="mt-3">
                    <Col md={8}>Zwischensumme</Col>
                    <Col className="right">
                      {cartItems
                        .reduce(
                          (acc, item) => {
                            if (
                              userInfo &&
                              userInfo.qrcnumber !== 0 &&
                              item.qrcprice !== "0"
                            )
                              return acc + 1 * item.qrcprice;
                            else return acc + 1 * item.price;
                          },

                          0
                        )
                        .toFixed(2)}{" "}
                      €
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col md={8}>Lieferung (Digitalprodukt)</Col>
                    <Col className="right">0 €</Col>
                  </Row>
                  <Row>
                    <Col md={8}>inkl. MwSt 19 %</Col>
                    <Col className="right">
                      {cartItems
                        .reduce(
                          (acc, item) => {
                            if (
                              userInfo &&
                              userInfo.qrcnumber !== 0 &&
                              item.qrcprice !== "0"
                            )
                              return acc + ((1 * item.qrcprice) / 1.19) * 0.19;
                            else return acc + ((1 * item.price) / 1.19) * 0.19;
                          },

                          0
                        )
                        .toFixed(2)}{" "}
                      €
                    </Col>
                  </Row>

                  <Row className="mt-3">
                    <Col md={8}>
                      <b>Gesamtsumme</b>
                    </Col>
                    <Col className="right">
                      <b>
                        {cartItems
                          .reduce(
                            (acc, item) => {
                              if (
                                userInfo &&
                                userInfo.qrcnumber !== 0 &&
                                item.qrcprice !== "0"
                              )
                                return acc + 1 * item.qrcprice;
                              else return acc + 1 * item.price;
                            },

                            0
                          )
                          .toFixed(2)}{" "}
                        €
                      </b>
                    </Col>
                  </Row>
                </ListGroup.Item>

                <ListGroup.Item>
                  <Button
                    type="button"
                    className="btn-block"
                    disabled={cartItems.length === 0}
                    onClick={checkoutHandler}
                  >
                    Zur Kasse
                  </Button>
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default CartPage;
