import React, { Component, Fragment } from "react";
import Footer from "../components/Footer/Footer";
import PageTop from "../components/PageTop/PageTop";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import { Container } from "react-bootstrap";
export class ContactPage extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }
  render() {
    return (
      <Fragment>
        <TopNavigation title="Kontakt" />
        <PageTop pagetitle="Kontakt" />

        <Container className="mt-5">
          <h2>Kontakt</h2>
          <div class="">
            <h4 class="contact_contacts_title__2kcbH">
              {" "}
              Friedrichstraße 94 <br /> 10117 Berlin <br /> Deutschland
            </h4>
            <a class="contact_number__3JBsW" href="tel:+030 95 9999 100">
              030 95 9999 100
            </a>
            <p class="contact_connect_description__2gf4m">
              <a href="mailto:office@bta-weiterbildung.de">
                office@bta-weiterbildung.de
              </a>
            </p>
          </div>
          <div class="contact_wrapper_title_form__LPPUJ">
            <h4 class="contact_title_form__22Ytb">
              Was können wir <span class="special-text">für dich tun?</span>
            </h4>
            <p class="contact_form_subtitle__2e_Sq">Wir freuen uns auf dich</p>
          </div>
        </Container>
        <Footer />
      </Fragment>
    );
  }
}

export default ContactPage;
