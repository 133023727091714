import React, { Component, Fragment } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import iconOne from '../../asset/image/design.png';
import iconTwo from '../../asset/image/web.png';
import iconWelt from '../../asset/image/welt.svg';
import iconIdee from '../../asset/image/idee.svg';
import iconWachsen from '../../asset/image/wachsen.svg';
import iconWachsen2 from '../../asset/image/wachsen2.svg';
import coaching1 from '../../asset/image/smiling-woman-and-wellness-coach-2021-08-26-15-45-37-utc.jpg';
import coaching2 from '../../asset/image/talking-in-group-2021-09-24-02-41-00-utc.jpg';
import { Link } from 'react-router-dom';

export class Services extends Component {
  render() {
    return (
      <Fragment>
        <Container className='text-center'>
          <h1 className='mainTitle'>Inhalte</h1>
          <div className='bottomLine'></div>
          <Row className='marBo200'>
            <Col lg={6} md={6} sm={12}>
              <Link to='/trainings' style={{ textDecoration: 'none' }}>
                <div className='serviceCard text-center'>
                  <img className='iconOne' src={iconOne} alt='' />
                  <h2 className='serviceName'>Trainings</h2>
                  <p
                    className='serviceDescription'
                    style={{ color: '#000000' }}
                  >
                    An diesem Ort auf dem btaplanet geht es rund um das Thema
                    Trainings!
                  </p>
                </div>
              </Link>
            </Col>

            <Col lg={6} md={6} sm={12}>
              <Link to='/peergroup' style={{ textDecoration: 'none' }}>
                <div className='serviceCard text-center'>
                  <img className='iconOne' src={iconTwo} alt='' />
                  <h2 className='serviceName'>Peer Groups</h2>
                  <p
                    className='serviceDescription'
                    style={{ color: '#000000' }}
                  >
                    An diesem Ort auf dem btaplanet geht es rund um das Thema
                    PEER GROUPS!
                  </p>
                </div>
              </Link>
            </Col>
          </Row>
          <Row className='marBo200'>
            <Col lg={3} md={12} sm={12} xs={12}>
              <div>
                <img className='iconOne' src={iconWelt} alt='' />
                <p>
                  Der bta planet ist eine Welt des Austausches, Gemeinsamkeit,
                  Wachstums und Kooperation!{' '}
                </p>
              </div>
            </Col>

            <Col lg={3} md={12} sm={12} xs={12}>
              <div>
                <img className='iconOne' src={iconWachsen} alt='' />
                <p>
                  Mit dem btaplanet bieten wir eine Plattform für
                  Coaching&Training <br />
                  Der btaplanet ist nachhaltig, visionär und friedlich!
                </p>
              </div>
            </Col>
            <Col lg={3} md={12} sm={12} xs={12}>
              <div>
                <img className='iconOne' src={iconWachsen2} alt='' />
                <p>
                  Der btaplanet ist die lebendige bta Welt in der alle
                  profitieren und gemeinsam wachsen!
                </p>
              </div>
            </Col>
            <Col lg={3} md={12} sm={12} xs={12}>
              <div>
                <img className='iconOne' src={iconIdee} alt='' />
                <p>
                  Der btaplanet bietet einen Mehrwert für alle – weil sie
                  gemeinsam lernen und lehren!
                </p>
              </div>
            </Col>
          </Row>

          <Row className='marBo200'>
            <Col lg={6} md={12} sm={12}>
              <img style={{ width: '100%' }} src={coaching1} alt='' />
            </Col>

            <Col
              lg={6}
              md={12}
              sm={12}
              className='d-flex justify-content-center align-items-center text-left'
            >
              <p>
                <span className='serviceName'>
                  Freie Peer Groups und Intervisionsgruppen
                </span>
                <br />
                Ihr könnt selbst Peer Groups und Intervisionsgruppen
                organisieren und durchführen. Bei angeleiteten Peer Groups könnt
                ihr CCE Punkte für den QRC Qualitätsring Coaching und Beratung
                e.V. generieren und somit weiter upgraden
              </p>
            </Col>
          </Row>

          <Row className='marBo200' style={{ marginBottom: '300px!important' }}>
            <Col
              lg={6}
              md={12}
              sm={12}
              className='d-flex justify-content-center align-items-center text-left'
            >
              <p>
                <span className='serviceName'>
                  Trainings rund um das Thema Coaching & Training
                </span>
                <br />
                Es werden regelmäßig Workshops zu verschiedenen Themen rund um
                das Thema Coaching & Training von DozentInnen angeboten –
                vertieft Themen, die ihr aus der Weiterbildung der bta kennt
                oder komplettiert euer Wissen durch neue Themenanagebote.
                <br />
                Gerne nehmen wir auch Anregungen und Wünsche entgegen.
                <br />
                <span className='serviceName'>
                  Happy and good life on btaplanet!
                </span>{' '}
                <br />
                <b className='serviceName'>Euer bta-team.</b>
              </p>
            </Col>

            <Col lg={6} md={12} sm={12}>
              <img style={{ width: '100%' }} src={coaching2} alt='' />
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default Services;
