import React, { Component, Fragment } from "react";
import { Col, Container, Row, Button } from "react-bootstrap";
import { NavLink } from "react-router-dom";

class TopBanner extends Component {
  render() {
    return (
      <Fragment>
        <Container className="topFixedBanner p-0" fluid={true}>
          <div className="topBannerOverlay">
            <Container className="topContent">
              <Row>
                <Col className="text-center">
                  <h1 className="topTitle">Coaching & Training</h1>
                  <br />

                  <NavLink to="/register" className="footerLink">
                    <Button variant="primary"> Werde Teil der Familie</Button>
                  </NavLink>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </Fragment>
    );
  }
}

export default TopBanner;
