import React, { useState, useEffect, Fragment } from "react";
import Footer from "../../components/Footer/Footer";
import PageTop from "../../components/PageTop/PageTop";
import TopNavigation from "../../components/TopNavigation/TopNavigation";
import { Link } from "react-router-dom";
import { Form, Button, Container, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import {
  listProductDetailsAdmin,
  updateProduct,
  listProductUser,
} from "../../actions/peergroupAction";
import {
  PRODUCT_UPDATE_RESET,
  PEERGROUP_DETAILS_RESET,
} from "../../constants/peerGroupConstans";

const ProductEditScreen = ({ match, history }) => {
  const productId = match.params.id;
  const [title, setTitle] = useState("");
  const [price, setPrice] = useState(0);
  const [qrcprice, setQrcprice] = useState(0);
  const [date, setDate] = useState(0);
  const [time, setTime] = useState(0);

  const [trainer, setTrainer] = useState("trainer");
  const [shortdescription, setShortdescription] = useState("");
  const [description, setDescription] = useState("");
  const [cce, setCce] = useState("");
  const [zoomlink, setZoomlink] = useState("");
  const [zoompass, setZoompass] = useState("");
  const [userpeergroup, setUserpeergroup] = useState(false);
  const [usertraining, setUsertraining] = useState(false);
  const [btapeergroup, setBtapeergroup] = useState(false);
  const [btatraining, setBtatraining] = useState(false);
  const [maxtn, setMaxtn] = useState(14);
  const [hidden, setHidden] = useState("");

  const dispatch = useDispatch();

  const productUserList = useSelector((state) => state.productUserList);
  const { user } = productUserList;

  const productDetails = useSelector((state) => state.productDetails);
  const { loading, error, product } = productDetails;

  const productUpdate = useSelector((state) => state.productUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = productUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: PRODUCT_UPDATE_RESET });

      dispatch({ type: PEERGROUP_DETAILS_RESET });
      history.push("/admin/produkte");
    } else {
      if (!product.title || product._id !== productId) {
        dispatch(listProductDetailsAdmin(productId));
      } else {
        setTitle(product.title);
        setPrice(product.price);
        setQrcprice(product.qrcprice);
        setDate(product.date);
        setTime(product.time);
        setTrainer(product.trainer);
        setShortdescription(product.shortdescription);
        setDescription(product.description);
        setCce(product.cce);
        setZoomlink(product.zoomlink);
        setZoompass(product.zoompass);
        setUserpeergroup(product.userpeergroup);
        setUsertraining(product.usertraining);
        setBtapeergroup(product.btapeergroup);
        setBtatraining(product.btatraining);
        setMaxtn(product.maxtn);
        setHidden(product.hidden);
        dispatch(listProductUser(productId));
      }
    }
  }, [dispatch, history, productId, product, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateProduct({
        _id: productId,
        title,
        price,
        qrcprice,
        date,
        time,
        trainer,
        shortdescription,
        description,
        cce,
        zoomlink,
        zoompass,
        userpeergroup,
        usertraining,
        btapeergroup,
        btatraining,
        maxtn,
        hidden,
      })
    );
  };
  return (
    <Fragment>
      <TopNavigation title="User bearbeiten" />
      <PageTop pagetitle="Training oder Peergroup bearbeiten" />

      <Container className="my-5">
        <Link to="/admin/produkte" className="btn btn-light my-3">
          Zurück
        </Link>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant="danger">{error}</Message>}
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Row className="p-2 mt-2">
              <Col className="p-0 " md={9} lg={9} sm={9} xs={9}>
                <Form.Group controlId="title">
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type="name"
                    placeholder="Title"
                    value={title}
                    onChange={(e) => setTitle(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="price">
                  <Form.Label>Preis</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="Preis"
                    value={price}
                    onChange={(e) => setPrice(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="qrcprice">
                  <Form.Label>QRC Preis</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="QRC Preis"
                    value={qrcprice}
                    onChange={(e) => setQrcprice(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="maxtn">
                  <Form.Label>Max TN</Form.Label>
                  <Form.Control
                    type="number"
                    placeholder="MaxTn"
                    value={maxtn}
                    onChange={(e) => setMaxtn(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="cce">
                  <Form.Label>CCE</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="CCE"
                    value={cce}
                    onChange={(e) => setCce(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="date">
                  <Form.Label>Datum</Form.Label>
                  <Form.Control
                    type="date"
                    value={date}
                    onChange={(e) => setDate(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="time">
                  <Form.Label>Zeit</Form.Label>
                  <Form.Control
                    type="text"
                    value={time}
                    onChange={(e) => setTime(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="trainer">
                  <Form.Label>Trainer</Form.Label>
                  <Form.Control
                    type="text"
                    value={trainer}
                    onChange={(e) => setTrainer(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId="shortdescription">
                  <Form.Label>Kurzbeschreibung</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Kurzbeschreibung"
                    as="textarea"
                    value={shortdescription}
                    onChange={(e) => setShortdescription(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="description">
                  <Form.Label>Beschreibung</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Beschreibung"
                    as="textarea"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="zoomlink">
                  <Form.Label>Zoomlink</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Beschreibung"
                    value={zoomlink}
                    onChange={(e) => setZoomlink(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="zoompass">
                  <Form.Label>Zoompasswort</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Passwort"
                    value={zoompass}
                    onChange={(e) => setZoompass(e.target.value)}
                  ></Form.Control>
                </Form.Group>
              </Col>

              <Col
                className="p-0  mx-3"
                style={{ marginTop: "28px" }}
                md={2}
                lg={2}
                sm={2}
                xs={2}
              >
                <Form.Group controlId="userpeergroup">
                  <Form.Check
                    type="checkbox"
                    label="userpeergroup"
                    checked={userpeergroup}
                    onChange={(e) => setUserpeergroup(e.target.checked)}
                  ></Form.Check>
                </Form.Group>

                <Form.Group controlId="usertraining">
                  <Form.Check
                    type="checkbox"
                    label="usertraining"
                    checked={usertraining}
                    onChange={(e) => setUsertraining(e.target.checked)}
                  ></Form.Check>
                </Form.Group>

                <Form.Group controlId="btapeergroup">
                  <Form.Check
                    type="checkbox"
                    label="btapeergroup"
                    checked={btapeergroup}
                    onChange={(e) => setBtapeergroup(e.target.checked)}
                  ></Form.Check>
                </Form.Group>
                <Form.Group controlId="btatraining">
                  <Form.Check
                    type="checkbox"
                    label="btatraining"
                    checked={btatraining}
                    onChange={(e) => setBtatraining(e.target.checked)}
                  ></Form.Check>
                </Form.Group>

                <Form.Group controlId="oeffentlich">
                  <Form.Check
                    type="checkbox"
                    label="Offline"
                    checked={hidden}
                    onChange={(e) => setHidden(e.target.checked)}
                  ></Form.Check>
                </Form.Group>
              </Col>
            </Row>
            <Button type="submit" variant="primary">
              Update
            </Button>
          </Form>
        )}
        <h2 className="mt-5">Teilnehmer</h2>
        <Table striped bordered hover responsive className="table-sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>email</th>
              <th>cce</th>
            </tr>
          </thead>

          <tbody>
            {user.map((tn) => (
              <tr key={tn._id}>
                <td>{tn.user.name}</td>
                <td>{tn.user.email}</td>
                <td>{tn.user.cce}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </Container>

      <Footer />
    </Fragment>
  );
};

export default ProductEditScreen;
