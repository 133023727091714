import React, { Fragment, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import PageTop from "../../components/PageTop/PageTop";
import PeerGroupList from "../../components/Admin/PeerGroupList";
import TopNavigation from "../../components/TopNavigation/TopNavigation";

const ProductListScreen = ({ match, history }) => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <Fragment>
      <TopNavigation title="Peer Groups" />
      <PageTop pagetitle="Peer Groups / Trainings" />
      <PeerGroupList match={match} history={history} />
      <Footer />
    </Fragment>
  );
};

export default ProductListScreen;
