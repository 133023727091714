import React, { Component, Fragment } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import AgbPage from "../pages/AgbPage";
import BlogsPage from "../pages/BlogsPage";
import BtaPage from "../pages/BtaPage";
import ContactPage from "../pages/ContactPage";
import Dashboard from "../pages/Dashboard/Dashboard";
import DatenschutzPage from "../pages/DatenschutzPage";
import ForgetPasswordPage from "../pages/ForgetPasswordPage";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/LoginPage";
import NetiquettePage from "../pages/NetiquettePage";
import PeergroupPage from "../pages/PeergroupPage";
import RegisterPage from "../pages/RegisterPage";
import ResetPasswordPage from "../pages/ResetPasswordPage";
import WorkshopPage from "../pages/WorkshopPage";
import BlogPage from "../pages/BlogPage";
import ProductEditScreen from "../pages/Admin/ProductEditScreen";
import BlogEditScreen from "../pages/Admin/BlogEditScreen";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserListScreen from "../pages/Admin/UserListScreen";
import BlogListScreen from "../pages/Admin/BlogListScreen";
import ProductListScreen from "../pages/Admin/ProductListScreen";
import OrderListScreen from "../pages/Admin/OrderListScreen";
import UserEditScreen from "../pages/Admin/UserEditScreen";
import CartPage from "../pages/CartPage";
import ShippingPage from "../pages/ShippingPage";
import PaymentPage from "../pages/PaymentPage";
import PlaceOrderPage from "../pages/PlaceOrderPage";
import OrderPage from "../pages/OrderPage";

import BlogUser from "../pages/Dashboard/BlogEinreichenPage";
import TrainingUser from "../pages/Dashboard/TrainingEinreichenPage";

class AppRouter extends Component {
  render() {
    return (
      <Fragment>
        <Router>
          <Switch>
            <Route exact path="/" component={HomePage}></Route>
            <Route
              exact
              path="/blogs/page/:pageNumber"
              component={BlogsPage}
            ></Route>

            <Route
              exact
              path="/peergroup/page/:pageNumber"
              component={PeergroupPage}
            ></Route>
            <Route
              exact
              path="/search/:keyword/page/:pageNumber"
              component={BlogsPage}
            ></Route>
            <Route path="/search/:keyword" component={BlogsPage}></Route>
            <Route exact path="/peergroup" component={PeergroupPage} />
            <Route exact path="/trainings" component={WorkshopPage} />
            <Route exact path="/blogs" component={BlogsPage} />
            <Route path="/blog/:url" component={BlogPage} />
            <Route exact path="/netiquette" component={NetiquettePage} />
            <Route exact path="/login" component={LoginPage} />
            <Route exact path="/register" component={RegisterPage} />
            <Route exact path="/bta" component={BtaPage} />
            <Route exact path="/kontakt" component={ContactPage} />
            <Route exact path="/agb" component={AgbPage} />
            <Route exact path="/datenschutz" component={DatenschutzPage} />
            <Route exact path="/dashboard" component={Dashboard} />

            <Route exact path="/bloguser" component={BlogUser} />
            <Route exact path="/traininguser" component={TrainingUser} />

            <Route exact path="/admin/userlist" component={UserListScreen} />
            <Route exact path="/admin/bloglist" component={BlogListScreen} />
            <Route exact path="/admin/orderlist" component={OrderListScreen} />
            <Route exact path="/cart/:id?" component={CartPage} />
            <Route path="/shipping" component={ShippingPage} />
            <Route path="/payment" component={PaymentPage} />
            <Route path="/placeorder" component={PlaceOrderPage} />
            <Route path="/orders/:id" component={OrderPage} />
            <Route
              exact
              path="/admin/user/:id/edit"
              component={UserEditScreen}
            />
            <Route
              exact
              path="/admin/product/:id/edit"
              component={ProductEditScreen}
            />

            <Route
              exact
              path="/admin/blog/:id/edit"
              component={BlogEditScreen}
            />
            <Route exact path="/admin/produkte" component={ProductListScreen} />

            <Route
              exact
              path="/resetpassword/:id"
              component={ResetPasswordPage}
            />
            <Route
              exact
              path="/passwortvergessen"
              component={ForgetPasswordPage}
            />
          </Switch>
        </Router>
        <ToastContainer />
      </Fragment>
    );
  }
}

export default AppRouter;
