import React, { Component, Fragment } from "react";

import Description from "../components/Description/Description";
import Footer from "../components/Footer/Footer";
import { Services } from "../components/Services/Services";
import TopBanner from "../components/TopBanner/TopBanner";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import TrennerPic from "../components/TrennerPic/TrennerPic";

export class HomePage extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }
  render() {
    return (
      <Fragment>
        <TopNavigation title="Home" />
        <TopBanner />
        <Services />
        <TrennerPic />
        <Description />

        <Footer />
      </Fragment>
    );
  }
}

export default HomePage;
