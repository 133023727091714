import React from "react";
import { useSelector } from "react-redux";
import { Card } from "react-bootstrap";

const LeftSide = () => {
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  return (
    <Card className="ProfileCard">
      <Card.Img
        className="ProfileCardBackgroundImage"
        alt="Background Image"
        variant="top"
        src="./bg-pattern-card.jpg"
      />
      <Card.Img
        className="ProfileCardImage"
        alt="User Image"
        src={userInfo.userImage}
      />
      <Card.Body
        className="text-center  ProfileCardBody "
        style={{
          border: "1px solid lightgrey",
          borderBottom: "0px solid #fff",
        }}
      >
        <Card.Text className="TextBold mb-0">{userInfo.name}</Card.Text>
        <Card.Text className="TextMuted"> {userInfo.email} </Card.Text>

        <Card.Text className="TextBold FooterP ">
          {" "}
          Du hast {userInfo.cce} CCE bei uns gesammelt{" "}
        </Card.Text>
        <Card.Text className="TextMuted">
          Deine QRC Mitgliedsnummer: {userInfo.qrcnumber}{" "}
        </Card.Text>
      </Card.Body>
      <Card.Footer
        className="CardFooter  "
        style={{
          border: "1px solid lightgrey",
          borderTop: "0px solid #fff",
        }}
      >
        {/*
        <Row xs="3" className="text-center mb-1 ">
          <Col>
            <Card.Text className="TextBold FooterP ">0</Card.Text>
            <Card.Text className="TextMuted"> Blogs</Card.Text>
          </Col>
          <Col>
            <Card.Text className="TextBold FooterP">0</Card.Text>
            <Card.Text className="TextMuted"> Peergroups</Card.Text>
          </Col>
          <Col>
            <Card.Text className="TextBold FooterP">0</Card.Text>
            <Card.Text className="TextMuted"> Trainings</Card.Text>
          </Col>
        </Row>
         */}
      </Card.Footer>
    </Card>
  );
};

export default LeftSide;
