import React, { Fragment, useEffect, useState } from "react";
import { PayPalButton } from "react-paypal-button-v2";
import axios from "axios";
import Footer from "../components/Footer/Footer";
import PageTop from "../components/PageTop/PageTop";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import { Button, Container } from "react-bootstrap";
import Loader from "../components/Loader";
import { Row, Col, ListGroup, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import {
  getOrderDetails,
  payOrder,
  sendPdfAfterPay,
} from "../actions/orderActions";

import { ORDER_PAY_RESET } from "../constants/orderConstans";

const OrderPage = ({ match, history }) => {
  const orderId = match.params.id;
  const [sdkReady, setSdkReady] = useState(false);
  const dispatch = useDispatch();

  const orderDetails = useSelector((state) => state.orderDetails);
  const { order, loading, error } = orderDetails;

  const orderPay = useSelector((state) => state.orderPay);
  const { loading: loadingPay, success: successPay } = orderPay;

  const orderSendPdf = useSelector((state) => state.orderSendPdf);
  const { loading: loadingOrderSendPdf, success: successOrderSendPdf } =
    orderSendPdf;
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  if (!loading) {
    //   Calculate prices
    const addDecimals = (num) => {
      return (Math.round(num * 100) / 100).toFixed(2);
    };

    order.itemsPrice = addDecimals(
      order.orderItems.reduce((acc, item) => acc + item.price * 1, 0)
    );
  }

  useEffect(() => {
    if (!userInfo) {
      history.push("/login");
    }

    const addPayPalScript = async () => {
      const { data: clientId } = await axios.get("/api/config/paypal");
      const script = document.createElement("script");
      script.type = "text/javascript";
      script.src = `https://www.paypal.com/sdk/js?currency=EUR&client-id=${clientId}`;
      script.async = true;
      script.onload = () => {
        setSdkReady(true);
      };
      document.body.appendChild(script);
    };

    if (!order || successPay || order._id !== orderId) {
      dispatch({ type: ORDER_PAY_RESET });

      dispatch(getOrderDetails(orderId));
    } else if (!order.isPaid) {
      if (!window.paypal) {
        addPayPalScript();
      } else {
        setSdkReady(true);
      }
    }
  }, [dispatch, orderId, successPay, order, history, userInfo]);

  const [send, setSend] = useState(false);

  const successPaymentHandler = (paymentResult) => {
    console.log(paymentResult);
    dispatch(payOrder(orderId, paymentResult));
    dispatch(sendPdfAfterPay(orderId));
  };

  const sendPdfHandler = () => {
    dispatch(sendPdfAfterPay(orderId));
    setSend(true);
  };

  return (
    <Fragment>
      <TopNavigation title="Kasse" />
      <PageTop pagetitle="Übersicht" />
      <Container className="my-5"></Container>

      <Container>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger">{error}</Message>
        ) : (
          <>
            <Row>
              <Col md={8}>
                <ListGroup variant="flush">
                  <ListGroup.Item className="mb-4" style={{ border: "0" }}>
                    <h2>Bestellung</h2>
                    <p>
                      {" "}
                      <strong>Bestellnummer:</strong> {order._id}
                    </p>
                    <p>
                      {" "}
                      <strong>Name:</strong> {order.user.name}
                    </p>
                    <p>
                      <strong>E-Mail: </strong>
                      <a href={`mailto:${order.user.email}`}>
                        {order.user.email}
                      </a>
                    </p>
                    <p>
                      <strong>Rechnungsadresse: </strong>
                      {order.shippingAddress.name},{" "}
                      {order.shippingAddress.address},{" "}
                      {order.shippingAddress.postalCode}{" "}
                      {order.shippingAddress.city}
                    </p>
                  </ListGroup.Item>

                  <ListGroup.Item style={{ border: "0" }}>
                    {order.totalPrice !== 0 && (
                      <>
                        <h2>Zahlung</h2>
                        <p>
                          <strong>Methode: </strong>
                          via {order.paymentMethod}
                        </p>
                      </>
                    )}

                    {order.isPaid ? (
                      <>
                        <Message variant="success">
                          Bezahlt am{" "}
                          {new Date(order.paidAt).toLocaleDateString()} um{" "}
                          {new Date(order.paidAt).toLocaleTimeString()} Uhr{" "}
                          <br /> Wir haben dir nach dem Kauf eine E-Mail mit
                          Zugangsdaten für Zoom geschickt.
                        </Message>

                        <Button
                          type="submit"
                          className="p-2 w-100 btn btn-success"
                          onClick={sendPdfHandler}
                          style={{
                            color: "#fff",
                            fontWeight: "bold",
                            width: "50px",
                          }}
                        >
                          PDF mit Zugangsdaten erneut per Mail senden
                        </Button>
                      </>
                    ) : order.totalPrice === 0 ? (
                      <Message variant="info">keine Kosten</Message>
                    ) : (
                      <Message variant="danger">Bitte noch bezahlen</Message>
                    )}
                  </ListGroup.Item>

                  {order.totalPrice === 0 && (
                    <Button
                      type="submit"
                      className="p-2 w-100 btn btn-success"
                      onClick={sendPdfHandler}
                      style={{
                        color: "#fff",
                        fontWeight: "bold",
                        width: "50px",
                      }}
                    >
                      PDF mit Zugangsdaten per Mail senden
                    </Button>
                  )}
                  {send ? (
                    <Message variant="danger">
                      Nachricht wurde versendet
                    </Message>
                  ) : (
                    ""
                  )}
                  <ListGroup.Item className="mt-4" style={{ border: "0" }}>
                    <h2>Bestellte Trainings/Peergroups</h2>
                    {order.orderItems.length === 0 ? (
                      <Message>Dein Warenkorb ist leer</Message>
                    ) : (
                      <ListGroup variant="flush">
                        {order.orderItems.map((item, index) => (
                          <ListGroup.Item key={index} style={{ border: "0" }}>
                            <Row>
                              <Col>
                                <b>- {item.title}</b>
                              </Col>
                              <Col md={4}>
                                {item.price === 0 ? "kostenlos" : ""}
                                {userInfo.qrcnumber !== 0 && item.qrcprice !== 0
                                  ? `QRC Preis ${item.qrcprice} €`
                                  : ""}
                                {item.qrcprice === 0 && item.price !== 0
                                  ? `${item.price} €`
                                  : ""}

                                {userInfo.qrcnumber === 0 && item.qrcprice !== 0
                                  ? `${item.price} €`
                                  : ""}
                              </Col>
                            </Row>
                            <Row>
                              <Col> {item.shortdescription}</Col>
                            </Row>
                          </ListGroup.Item>
                        ))}
                      </ListGroup>
                    )}
                  </ListGroup.Item>
                </ListGroup>
              </Col>
              <Col md={4}>
                <Card>
                  <ListGroup variant="flush">
                    <ListGroup.Item>
                      <h2>Zusammenfassung</h2>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>Trainings / Peergroups</Col>
                        <Col>
                          {new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR",
                          }).format(order.totalPrice)}{" "}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>Lieferung</Col>
                        <Col>
                          {new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR",
                          }).format(0)}{" "}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>inkl. 19% MwSt</Col>
                        <Col>
                          {new Intl.NumberFormat("de-DE", {
                            style: "currency",
                            currency: "EUR",
                          }).format((order.totalPrice / 1.19) * 0.19)}
                        </Col>
                      </Row>
                    </ListGroup.Item>
                    <ListGroup.Item>
                      <Row>
                        <Col>Total</Col>
                        <Col>
                          <b>
                            {new Intl.NumberFormat("de-DE", {
                              style: "currency",
                              currency: "EUR",
                            }).format(order.totalPrice)}
                          </b>
                        </Col>
                      </Row>
                    </ListGroup.Item>

                    {!order.isPaid && (
                      <ListGroup.Item>
                        {loadingPay && <Loader />}
                        {!sdkReady ? (
                          <Loader />
                        ) : (
                          <PayPalButton
                            amount={order.totalPrice}
                            onSuccess={successPaymentHandler}
                            currency="EUR"
                          />
                        )}
                      </ListGroup.Item>
                    )}
                  </ListGroup>
                </Card>
              </Col>
            </Row>
          </>
        )}
      </Container>
      <Footer />
    </Fragment>
  );
};

export default OrderPage;
