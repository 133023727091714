import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../components/Footer/Footer";
import PageTop from "../components/PageTop/PageTop";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import { listBlogDetails } from "../actions/blogAction";
import { Container, Row, Col, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import Loader from "../components/Loader";
import Message from "../components/Message";

const BlogPage = ({ match }) => {
  const dispatch = useDispatch();
  const blogDetails = useSelector((state) => state.blogDetails);
  const { blog, loading, error } = blogDetails;

  useEffect(() => {
    dispatch(listBlogDetails(match.params.url));
  }, [dispatch, match]);

  return (
    <Fragment>
      <TopNavigation title="Blog" />
      <PageTop pagetitle="" />
      <Container className="mt-5">
        <Link className="btn btn-light my-3" to="/blogs">
          Zurück
        </Link>

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger"> {error}</Message>
        ) : (
          <>
            <Row className="g-4">
              <Col md={8}>
                <h3>{blog.name} </h3>
                <Row>
                  <Col md={8}>{blog.shortdescription}</Col>
                  <Image src={blog.image} alt={blog.name} width="100%" />
                </Row>
              </Col>
            </Row>
            <Row>
              <Col md={8}>
                <div dangerouslySetInnerHTML={{ __html: blog.description }} />
              </Col>
            </Row>
          </>
        )}
      </Container>
      <Footer />
    </Fragment>
  );
};

export default BlogPage;
