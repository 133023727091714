import axios from 'axios';
import React, { useState, useEffect, Fragment } from 'react';
import Footer from '../../components/Footer/Footer';
import PageTop from '../../components/PageTop/PageTop';
import TopNavigation from '../../components/TopNavigation/TopNavigation';
import { Link } from 'react-router-dom';
import { Form, Button, Container, Col, Row } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import Message from '../../components/Message';
import Loader from '../../components/Loader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

import { listBlogDetailsEdit, updateBlog } from '../../actions/blogAction';
import { BLOG_UPDATE_RESET } from '../../constants/blogConstans';

const BlogEditScreen = ({ match, history }) => {
  const blogId = match.params.id;

  const [name, setName] = useState('');
  const [url, setUrl] = useState('');
  const [image, setImage] = useState('');
  const [shortdescription, setShortdescription] = useState('');
  const [description, setDescription] = useState('');
  const [hidden, setHidden] = useState('');
  const [uploading, setUploading] = useState(false);

  const dispatch = useDispatch();

  const blogDetails = useSelector((state) => state.blogDetails);
  const { loading, error, blog } = blogDetails;

  const blogUpdate = useSelector((state) => state.blogUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = blogUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: BLOG_UPDATE_RESET });
      history.push('/admin/bloglist');
    } else {
      if (!blog.name || blog._id !== blogId) {
        dispatch(listBlogDetailsEdit(blogId));
      } else {
        setName(blog.name);
        setUrl(blog.url);
        setImage(blog.image);
        setShortdescription(blog.shortdescription);
        setDescription(blog.description);
        setHidden(blog.hidden);
      }
    }
  }, [dispatch, history, blogId, blog, successUpdate]);

  const uploadFileHandler = async (e) => {
    const file = e.target.files[0];
    const formData = new FormData();
    formData.append('image', file);
    setUploading(true);

    try {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      const { data } = await axios.post('/api/upload', formData, config);

      setImage(data);
      setUploading(false);
    } catch (error) {
      console.error(error);
      setUploading(false);
    }
  };

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      updateBlog({
        _id: blogId,
        name,
        url,
        image,
        shortdescription,
        description,
        hidden,
      })
    );
  };

  return (
    <Fragment>
      <TopNavigation title='User bearbeiten' />
      <PageTop pagetitle='Blog bearbeiten' />

      <Container className='my-5'>
        <Link to='/admin/bloglist' className='btn btn-light my-3'>
          Zurück
        </Link>
        {loadingUpdate && <Loader />}
        {errorUpdate && <Message variant='danger'>{error}</Message>}

        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant='danger'>{error}</Message>
        ) : (
          <Form onSubmit={submitHandler}>
            <Row className='p-2 mt-2'>
              <Col className='p-0 ' md={9} lg={9} sm={9} xs={9}>
                <Form.Group controlId='title'>
                  <Form.Label>Title</Form.Label>
                  <Form.Control
                    type='name'
                    placeholder='Title'
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId='url'>
                  <Form.Label>URL</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='URL'
                    as='textarea'
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group>
                  <Form.Label>Bild</Form.Label>
                  <Form.Control
                    type='name'
                    placeholder='Title'
                    value={image}
                    onChange={(e) => setImage(e.target.value)}
                  ></Form.Control>
                  <Form.Control
                    type='file'
                    id='image-file'
                    label='Choose File'
                    custom
                    onChange={uploadFileHandler}
                  ></Form.Control>
                  {uploading && <Loader />}
                </Form.Group>

                <Form.Group controlId='hidden'>
                  <Form.Check
                    type='checkbox'
                    label='Offline'
                    checked={hidden}
                    onChange={(e) => setHidden(e.target.checked)}
                  ></Form.Check>
                </Form.Group>

                <Form.Group controlId='shortdescription'>
                  <Form.Label>Kurzbeschreibung</Form.Label>
                  <Form.Control
                    type='text'
                    placeholder='Kurzbeschreibung'
                    as='textarea'
                    value={shortdescription}
                    onChange={(e) => setShortdescription(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId='description'>
                  <Form.Label>Beschreibung</Form.Label>
                  <CKEditor
                    editor={ClassicEditor}
                    data={description}
                    onChange={(event, editor) => {
                      const desc = editor.getData();
                      setDescription(desc);
                    }}
                    config={{
                      ckfinder: {
                        uploadUrl: '/api/upload/upload',
                      },

                      image: {
                        resize: true,
                        toolbar: [
                          'imageStyle:full',
                          'imageStyle:side',
                          'imageStyle:alignLeft',
                          'imageStyle:alignCenter',
                          'imageStyle:alignRight',
                          '|',
                          'imageStyle:sideLeft',
                          '|',
                          'imageTextAlternative',
                        ],
                        styles: [
                          'full',
                          'side',
                          'alignLeft',
                          'alignCenter',
                          'alignRight',
                          {
                            name: 'sideLeft',
                            title: 'To the left side',

                            className: 'side-image-left',
                          },
                        ],
                        resizeUnit: 'px',
                      },
                    }}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Button type='submit' variant='primary'>
              Update
            </Button>
          </Form>
        )}
      </Container>

      <Footer />
    </Fragment>
  );
};

export default BlogEditScreen;
