import React, { Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import Footer from "../components/Footer/Footer";
import LoginForm from "../components/Login/LoginForm";
import PageTop from "../components/PageTop/PageTop";
import TopNavigation from "../components/TopNavigation/TopNavigation";

const LoginPage = ({ location, history }) => {
  const redirect = location.search ? location.search.split("=")[1] : "/";
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;
  useEffect(() => {
    if (userInfo) {
      history.push(redirect);
    }
  }, [history, userInfo, redirect]);

  return (
    <Fragment>
      <TopNavigation title="Login" />
      <PageTop pagetitle="Login" />
      <LoginForm />
      <Footer />
    </Fragment>
  );
};

export default LoginPage;
