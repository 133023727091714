import React, { useEffect } from "react";
import Loader from "../Loader";
import Message from "../Message";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Container } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import { useHistory } from "react-router-dom";
import { listOrders } from "../../actions/orderActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";

const OrderList = ({ location }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const orderList = useSelector((state) => state.orderList);
  const { loading, error, orders } = orderList;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  useEffect(() => {
    if (userInfo && userInfo.isAdmin) {
      dispatch(listOrders());
    } else {
      history.push("/login");
    }
  }, [dispatch, history, userInfo]);

  return (
    <Container className="text-center my-5">
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Table striped bordered hover responsive className="table-sm">
          <thead>
            <tr>
              <th>Bestelldatum</th>
              <th>Name</th>
              <th>Preis</th>
              <th>cce</th>
              <th>Bezahlt</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {orders.map((order) => (
              <tr key={order._id}>
                <td>
                  {new Date(
                    order.createdAt.substring(0, 10)
                  ).toLocaleDateString()}
                </td>
                <td>{order.user.name}</td>
                <td>{order.totalPrice} €</td> <td></td>
                <td>
                  {order.isPaid ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      size="1x"
                      style={{ color: "green" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimes}
                      size="1x"
                      style={{ color: "red" }}
                    />
                  )}
                </td>
                <td>
                  <LinkContainer to={`/orders/${order._id}`}>
                    <Button variant="light" className="btn-sm">
                      <FontAwesomeIcon
                        icon={faEdit}
                        size="1x"
                        style={{ color: "green" }}
                      />
                    </Button>
                  </LinkContainer>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default OrderList;
