import React, { useState } from 'react';
import { Row, Col, Card, Button, Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTasks } from '@fortawesome/free-solid-svg-icons';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { faBell } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router';
import peergroup from '../../asset/image/qrc_logo.jpeg';
const EventOverview = ({
  title,
  shortdescription,
  history,
  price,
  date,
  cce,
  description,
  time,
  trainer,
  id,
  userpeergroup,
  usertraining,
  btapeergroup,
  btatraining,
}) => {
  const [show, setShow] = useState(false);

  const addToCartHandler = () => {
    history.push(`/cart/${id}`);
  };

  return (
    <>
      <Col lg={3} md={3} sm={12}>
        <Card className='peerCard'>
          <Card.Header></Card.Header>
          <Card.Body>
            <p className='pName'>{title}</p>
            <p className='pDescription'>
              {shortdescription.substr(0, 100) + '...'}
            </p>
          </Card.Body>
          <Card.Footer>
            <Row>
              <Col lg={6} md={6} sm={6}>
                <Row>
                  <Col className='date'>
                    <FontAwesomeIcon
                      icon={faTasks}
                      size='1x'
                      className='my-auto mr-4'
                      style={{ marginRight: '5px' }}
                    />
                    {userpeergroup === true ? 'Peer Group' : ''}
                    {btapeergroup === true ? 'QRC Peer Group' : ''}

                    {usertraining === true ? 'User Training' : ''}
                    {btatraining === true ? 'BTA Taining' : ''}
                  </Col>
                </Row>
                <Row>
                  <Col className='date'>
                    <FontAwesomeIcon
                      icon={faCalendar}
                      size='1x'
                      className='my-auto mr-4'
                      style={{ marginRight: '5px' }}
                    />
                    {new Date(date).toLocaleDateString()}
                  </Col>
                </Row>
                <Row>
                  <Col className='date'>
                    {cce > 0 ? (
                      <>
                        <FontAwesomeIcon
                          icon={faBell}
                          size='1x'
                          className='my-auto mr-4'
                          style={{ marginRight: '5px' }}
                        />
                        CCE: {cce}
                      </>
                    ) : (
                      ''
                    )}
                  </Col>
                </Row>
              </Col>
              <Col className='price  '>
                {price > 0 ? (
                  <Button
                    type='submit'
                    className='p-2 w-100 btn btn-success'
                    onClick={() => setShow(true)}
                    style={{ color: '#fff', fontWeight: 'bold' }}
                  >
                    Infos
                  </Button>
                ) : (
                  <Button
                    type='submit'
                    className='p-2 w-100 btn btn-info'
                    onClick={() => setShow(true)}
                    style={{ color: '#fff', fontWeight: 'bold', width: '50px' }}
                  >
                    Teilnehmen
                  </Button>
                )}
              </Col>
            </Row>
          </Card.Footer>
        </Card>
      </Col>
      <Modal
        show={show}
        onHide={() => setShow(false)}
        dialogClassName='modal-90w'
        aria-labelledby='example-custom-modal-styling-title'
      >
        <Modal.Header closeButton>
          <Modal.Title id='example-custom-modal-styling-title'>
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col className='date'>
              <FontAwesomeIcon
                icon={faTasks}
                size='1x'
                className='my-auto mr-4'
                style={{ marginRight: '5px' }}
              />
              {userpeergroup === true ? 'Peer Group' : ''}
              {btapeergroup === true ? 'QRC Peer Group' : ''}

              {usertraining === true ? 'User Training' : ''}
              {btatraining === true ? 'BTA Taining' : ''}
            </Col>
            <Col className='date'>
              {cce > 0 ? (
                <>
                  <FontAwesomeIcon
                    icon={faBell}
                    size='1x'
                    className='my-auto mr-4'
                    style={{ marginRight: '5px' }}
                  />
                  CCE: {cce}
                </>
              ) : (
                ''
              )}
            </Col>
          </Row>
          <Row>
            <Col className='date'>
              <FontAwesomeIcon
                icon={faCalendar}
                size='1x'
                className='my-auto mr-4'
                style={{ marginRight: '5px' }}
              />

              {new Date(date).toLocaleDateString()}
            </Col>
            <Col className='date'>Uhrzeit: {time} </Col>
          </Row>
          <Row>
            <Col className='date'>LeiterIn: {trainer}</Col>
            <Col className='date'>Preis: {price} €</Col>
          </Row>
          <br />

          <div dangerouslySetInnerHTML={{ __html: description }}></div>
          <div>
            {btapeergroup === true ? (
              <div
                style={{
                  backgroundColor: 'rgb(243 243 243)',
                  borderRadius: '10px',
                  width: '100%',
                  height: '160px',
                  padding: '8px',
                }}
              >
                <div
                  style={{ marginTop: '20px', float: 'left', width: '290px' }}
                >
                  Das ist eine QRC Peer Group. Der QRC - Qualitätsring Coaching
                  und Beratung e. V. ist ein Verband, der aus aktiven Coaches
                  und Beratern besteht.
                </div>
                <img
                  src={peergroup}
                  alt=''
                  height='150px'
                  style={{
                    float: 'right',
                    marginBottom: '10px',
                    borderRadius: '70px',
                    marginLeft: '10px',
                  }}
                />
              </div>
            ) : (
              ''
            )}
          </div>
          <Button
            type='submit'
            className='p-2 w-100 btn btn-success'
            onClick={addToCartHandler}
            style={{ color: '#fff', fontWeight: 'bold', width: '50px' }}
          >
            Jetzt an {userpeergroup === true ? 'Peer Group' : ''}
            {btapeergroup === true ? 'QRC Peer Group' : ''}
            {usertraining === true ? 'Training' : ''}
            {btatraining === true ? 'Taining' : ''} teilnehmen
          </Button>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default withRouter(EventOverview);
