import React from "react";
import { Link } from "react-router-dom";
import { Col, Card } from "react-bootstrap";
const Blog = ({ blog }) => {
  return (
    <Col>
      <Card className="blogCard">
        <Link to={`/blog/${blog.url}`}>
          <Card.Img src={`${blog.image}`} />
        </Link>
        <Card.Body>
          <Link to={`/blog/${blog.url}`}>
            <Card.Title as="div" className="blogName">
              {blog.name}
            </Card.Title>

            <Card.Text as="div" className="blogDescription">
              {blog.shortdescription.length > 99 &&
                blog.shortdescription.substr(0, 100) + "..."}
              {blog.shortdescription.length < 99 && blog.shortdescription}
            </Card.Text>
          </Link>
        </Card.Body>
        <Card.Footer>
          <small className="text-muted " style={{ fontSize: "10px" }}>
            Erstellt von {blog.user.name} am{" "}
            {new Date(blog.createdAt).toLocaleDateString()}
          </small>
        </Card.Footer>
      </Card>
    </Col>
  );
};

export default Blog;
