import React, { Fragment, useEffect } from "react";
import Footer from "../components/Footer/Footer";
import PageTop from "../components/PageTop/PageTop";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import { Container } from "react-bootstrap";
import CheckoutSteps from "../components/CheckoutSteps";
import { Button, Row, Col, ListGroup, Card } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../components/Message";
import { Link } from "react-router-dom";
import { createOrder } from "../actions/orderActions";
import { CART_RESET } from "../constants/cartConstans";
import { USER_DETAILS_RESET } from "../constants/userConstans";
import { ORDER_CREATE_RESET } from "../constants/orderConstans";
const PlaceOrderPage = ({ history }) => {
  const dispatch = useDispatch();

  const cart = useSelector((state) => state.cart);
  const [checked, setChecked] = React.useState(false);
  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const handleChange = () => {
    setChecked(!checked);
  };

  //   Calculate prices
  const addDecimals = (num) => {
    return (Math.round(num * 100) / 100).toFixed(2);
  };

  cart.itemsPrice = addDecimals(
    cart.cartItems.reduce(
      (acc, item) => {
        if (userInfo && userInfo.qrcnumber !== 0 && item.qrcprice !== "0")
          return acc + 1 * item.qrcprice;
        else return acc + 1 * item.price;
      },

      0
    )
  );
  cart.shippingPrice = addDecimals(cart.itemsPrice > 100 ? 0 : 0);
  cart.taxPrice = addDecimals(
    Number(((cart.itemsPrice / 1.19) * 0.19).toFixed(2))
  );
  cart.totalPrice = (
    Number(cart.itemsPrice) + Number(cart.shippingPrice)
  ).toFixed(2);

  const orderCreate = useSelector((state) => state.orderCreate);
  const { order, success, error } = orderCreate;
  const placeOrderHandler = () => {
    dispatch({
      type: CART_RESET,
    });
    dispatch(
      createOrder({
        orderItems: cart.cartItems,
        shippingAddress: cart.shippingAddress,
        paymentMethod: cart.paymentMethod,
        itemsPrice: cart.itemsPrice,
        shippingPrice: cart.shippingPrice,
        taxPrice: cart.taxPrice,
        totalPrice: cart.totalPrice,
      })
    );
  };

  useEffect(() => {
    if (success) {
      history.push(`/orders/${order._id}`);
      dispatch({ type: USER_DETAILS_RESET });
      dispatch({ type: ORDER_CREATE_RESET });
    }
    // eslint-disable-next-line
  }, [history, success]);

  return (
    <Fragment>
      <TopNavigation title="Kasse" />
      <PageTop pagetitle="Bestellung abschließen" />
      <Container className="my-5"></Container>
      <CheckoutSteps step1 step2 step3 step4 />
      <Container>
        <Row>
          <Col md={8}>
            <ListGroup variant="flush" style={{ border: "0" }}>
              <ListGroup.Item style={{ border: "0" }} className="mb-4">
                <h2>Rechnungsadresse</h2>
                <p>
                  <strong>Adresse: </strong>
                  {cart.shippingAddress.name}, {cart.shippingAddress.address},{" "}
                  {cart.shippingAddress.postalCode} {cart.shippingAddress.city}
                </p>
              </ListGroup.Item>

              <ListGroup.Item style={{ border: "0" }} className="mb-4">
                <h2>Zahlung</h2>
                <strong>Methode: </strong>
                via {cart.paymentMethod}
              </ListGroup.Item>

              <ListGroup.Item style={{ border: "0" }}>
                <h2>Bestellte Trainings/Peergroups</h2>
                {cart.cartItems.length === 0 ? (
                  <Message>Dein Warenkorb ist leer</Message>
                ) : (
                  <ListGroup variant="flush">
                    {cart.cartItems.map((item, index) => (
                      <ListGroup.Item key={index} style={{ border: "0" }}>
                        <Row>
                          <Col>
                            <b>- {item.title}</b>
                          </Col>
                          <Col md={4}>
                            {userInfo.qrcnumber !== 0 && item.qrcprice !== "0"
                              ? `QRC Preis ${item.qrcprice} €`
                              : ""}

                            {userInfo.qrcnumber === 0 && item.qrcprice !== "0"
                              ? `${item.price} €`
                              : ""}

                            {userInfo.qrcnumber !== 0 && item.qrcprice === "0"
                              ? `${item.price} €`
                              : ""}

                            {userInfo.qrcnumber === 0 && item.qrcprice === "0"
                              ? `${item.price} €`
                              : ""}
                          </Col>
                        </Row>
                        <Row>
                          <Col> {item.shortdescription}</Col>
                        </Row>
                      </ListGroup.Item>
                    ))}
                  </ListGroup>
                )}
              </ListGroup.Item>
            </ListGroup>
          </Col>
          <Col md={4}>
            <Card>
              <ListGroup variant="flush">
                <ListGroup.Item>
                  <h2>Zusammenfassung</h2>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Trainings / Peergroups</Col>
                    <Col>
                      {new Intl.NumberFormat("de-DE", {
                        style: "currency",
                        currency: "EUR",
                      }).format(cart.itemsPrice)}{" "}
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Lieferung</Col>
                    <Col>
                      {new Intl.NumberFormat("de-DE", {
                        style: "currency",
                        currency: "EUR",
                      }).format(cart.shippingPrice)}{" "}
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>inkl 19% MwSt.</Col>
                    <Col>
                      {new Intl.NumberFormat("de-DE", {
                        style: "currency",
                        currency: "EUR",
                      }).format(cart.taxPrice)}
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Row>
                    <Col>Total</Col>
                    <Col>
                      <b>
                        {new Intl.NumberFormat("de-DE", {
                          style: "currency",
                          currency: "EUR",
                        }).format(cart.totalPrice)}
                      </b>
                    </Col>
                  </Row>
                </ListGroup.Item>
                <ListGroup.Item>
                  <span style={{ fontSize: "12px" }}>
                    Hiermit bestätige ich, dass ich die{" "}
                    <Link target="_blank" to={`/datenschutz`}>
                      Daten­schutz­erklärung
                    </Link>{" "}
                    sowie die{" "}
                    <Link target="_blank" to={`/datenschutz`}>
                      AGB
                    </Link>{" "}
                    gelesen habe.{" "}
                    <input
                      type="checkbox"
                      checked={checked}
                      onChange={handleChange}
                    />
                  </span>
                </ListGroup.Item>
                <ListGroup.Item>
                  <Button
                    type="button"
                    className="btn-block"
                    disabled={!checked}
                    onClick={placeOrderHandler}
                  >
                    Kostenpflichtig bestellen
                  </Button>
                </ListGroup.Item>
                <ListGroup.Item>
                  {error && <Message variant="danger">{error}</Message>}
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </Col>
        </Row>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default PlaceOrderPage;
