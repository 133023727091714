import React, { useEffect } from "react";
import Loader from "../Loader";
import Message from "../Message";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Container, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import {
  listProducts,
  deleteProduct,
  createProduct,
} from "../../actions/peergroupAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { PRODUCT_CREATE_RESET } from "../../constants/peerGroupConstans";
const PeerGroupList = ({ history, match }) => {
  const dispatch = useDispatch();

  const peerGroupList = useSelector((state) => state.peerGroupList);
  const { loading, error, peergroups } = peerGroupList;

  const productDelete = useSelector((state) => state.productDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = productDelete;

  const productCreate = useSelector((state) => state.productCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    product: createdProduct,
  } = productCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const createProductHandler = () => {
    dispatch(createProduct());
  };

  useEffect(() => {
    dispatch({
      type: PRODUCT_CREATE_RESET,
    });
    if (!userInfo.isAdmin) {
      history.push("/login");
    }
    if (successCreate) {
      history.push(`/admin/product/${createdProduct._id}/edit`);
    } else {
      dispatch(listProducts());
    }
  }, [
    dispatch,
    history,
    userInfo,
    successDelete,
    successCreate,
    createdProduct,
  ]);

  const deleteHandler = (id) => {
    if (window.confirm("Sicher?")) {
      dispatch(deleteProduct(id));
    }
  };

  return (
    <Container className=" my-5">
      <Row>
        <Col className="text-right">
          <Button className="my-3" onClick={createProductHandler}>
            <FontAwesomeIcon
              icon={faPlus}
              size="1x"
              style={{ color: "#fff" }}
            />{" "}
            Erstelle Training / PeerGroup
          </Button>
        </Col>
      </Row>

      {loadingDelete && <Loader />}
      {errorDelete && <Message variant="danger">{errorDelete}</Message>}
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant="danger">{errorCreate}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Table striped bordered hover responsive className="table-sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Datum</th>
              <th>Uhrzeit</th>
              <th>Preis</th>

              <th>BTA Training</th>
              <th>BTA PeerGroup</th>
              <th>TN Training</th>
              <th>TN PeerGroup</th>
              <th>Online</th>
            </tr>
          </thead>

          <tbody>
            {peergroups.map((product) => (
              <tr key={product._id}>
                <td>{product.title}</td>
                <td>{product.time}</td>
                <td>
                  {new Date(product.date.substring(0, 10)).toLocaleDateString()}

                  <br />
                  {product.time}
                </td>
                <td>{product.price} €</td>

                <td style={{ textAlign: "center" }}>
                  {product.btatraining ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      size="1x"
                      style={{ color: "green" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimes}
                      size="1x"
                      style={{ color: "red" }}
                    />
                  )}
                </td>
                <td style={{ textAlign: "center" }}>
                  {product.btapeergroup ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      size="1x"
                      style={{ color: "green" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimes}
                      size="1x"
                      style={{ color: "red" }}
                    />
                  )}
                </td>

                <td style={{ textAlign: "center" }}>
                  {product.usertraining ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      size="1x"
                      style={{ color: "green" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimes}
                      size="1x"
                      style={{ color: "red" }}
                    />
                  )}
                </td>
                <td style={{ textAlign: "center" }}>
                  {product.userpeergroup ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      size="1x"
                      style={{ color: "green" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimes}
                      size="1x"
                      style={{ color: "red" }}
                    />
                  )}
                </td>

                <td style={{ textAlign: "center" }}>
                  {!product.hidden ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      size="1x"
                      style={{ color: "green" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimes}
                      size="1x"
                      style={{ color: "red" }}
                    />
                  )}
                </td>
                <td>
                  <LinkContainer to={`/admin/product/${product._id}/edit`}>
                    <Button variant="light" className="btn-sm">
                      <FontAwesomeIcon
                        icon={faEdit}
                        size="1x"
                        style={{ color: "green" }}
                      />
                    </Button>
                  </LinkContainer>
                  <Button
                    variant="danger"
                    className="btn-sm"
                    onClick={() => deleteHandler(product._id)}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      size="1x"
                      style={{ color: "#fff" }}
                    />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default PeerGroupList;
