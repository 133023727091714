export const PEERGROUP_LIST_REQUEST = "PEERGROUP_LIST_REQUEST";
export const PEERGROUP_LIST_SUCCESS = "PEERGROUP_LIST_SUCCESS";
export const PEERGROUP_LIST_FAIL = "PEERGROUP_LIST_FAIL";

export const PEERGROUP_DETAILS_REQUEST = "PEERGROUP_DETAILS_REQUEST";
export const PEERGROUP_DETAILS_SUCCESS = "PEERGROUP_DETAILS_SUCCESS";
export const PEERGROUP_DETAILS_FAIL = "PEERGROUP_DETAILS_FAIL";
export const PEERGROUP_DETAILS_RESET = "PEERGROUP_DETAILS_RESET";

export const TRAINING_LIST_REQUEST = "TRAINING_LIST_REQUEST";
export const TRAINING_LIST_SUCCESS = "TRAINING_LIST_SUCCESS";
export const TRAINING_LIST_FAIL = "PEERGROUP_LIST_FAIL";

export const PRODUCT_DELETE_REQUEST = "PRODUCT_DELETE_REQUEST";
export const PRODUCT_DELETE_SUCCESS = "PRODUCT_DELETE_SUCCESS";
export const PRODUCT_DELETE_FAIL = "PRODUCT_DELETE_FAIL";

export const PRODUCT_CREATE_REQUEST = "PRODUCT_CREATE_REQUEST";
export const PRODUCT_CREATE_SUCCESS = "PRODUCT_CREATE_SUCCESS";
export const PRODUCT_CREATE_FAIL = "PRODUCT_CREATE_FAIL";
export const PRODUCT_CREATE_RESET = "PRODUCT_CREATE_RESET";

export const PRODUCT_UPDATE_REQUEST = "PRODUCT_UPDATE_REQUEST";
export const PRODUCT_UPDATE_SUCCESS = "PRODUCT_UPDATE_SUCCESS";
export const PRODUCT_UPDATE_FAIL = "PRODUCT_UPDATE_FAIL";
export const PRODUCT_UPDATE_RESET = "PRODUCT_UPDATE_RESET";

export const PRODUCT_USERS_REQUEST = "PRODUCT_USERS_REQUEST";
export const PRODUCT_USERS_SUCCESS = "PRODUCT_USERS_SUCCESS";
export const PRODUCT_USERS_FAIL = "PRODUCT_USERS_FAIL";
