import { Container, Row, Col } from 'react-bootstrap';
import workshop from '../asset/image/PMT_M121_05.svg';
import React, { Fragment, useEffect } from 'react';
import Footer from '../components/Footer/Footer';
import PageTop from '../components/PageTop/PageTop';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import EventOverview from '../components/EventOverview/EventOverview';
import { useDispatch, useSelector } from 'react-redux';
import { listTrainings } from '../actions/peergroupAction';
import Loader from '../components/Loader';
import Message from '../components/Message';
import { Link } from 'react-router-dom';

const WorkshopPage = ({ match }) => {
  const dispatch = useDispatch();
  const pageNumber = match.params.pageNumber || 1;
  const trainingsList = useSelector((state) => state.trainingsList);
  const { loading, error, trainings } = trainingsList;
  useEffect(() => {
    dispatch(listTrainings(pageNumber));
    window.scroll(0, 0);
  }, [dispatch, pageNumber]);

  return (
    <Fragment>
      <TopNavigation title='Trainings' />
      <PageTop pagetitle='Trainings' />
      <Container className='mt-5'>
        <Col className='mt-2 ' md={12} lg={12} sm={12} xs={12}>
          <div className='text-center'>
            <img
              src={workshop}
              alt=''
              style={{
                width: '30vw',
              }}
              responsive
            />
          </div>
          <Row className=''>
            <Col
              className='d-flex justify-content-center'
              md={6}
              lg={6}
              sm={12}
              xs={12}
            >
              <div className='serviceCard'>
                <h2 className='serviceName text-center'>Freie Trainings</h2>
                <p className='serviceDescription'>
                  An diesem Ort auf dem btaplanet könnt ihr eigene
                  Online-Workshops gestalten und anbieten. Lass alle von deinem
                  Herzensthema und Know-How profitieren. Du entscheidest wann,
                  wie lang und Teilnehmerzahl.
                </p>

                <Link
                  to='/traininguser'
                  className='btn btn-light my-3'
                  className='p-2 w-100 btn btn-info'
                  style={{ color: '#fff', fontWeight: 'bold' }}
                >
                  erstelle ein freies Training
                </Link>
              </div>
            </Col>

            <Col
              className='d-flex justify-content-center'
              md={6}
              lg={6}
              sm={12}
              xs={12}
            >
              <div className='serviceCard'>
                <h2 className='serviceName text-center'>BTA Trainings</h2>
                <p className='serviceDescription text-left '>
                  An diesem Ort auf dem btaplanet bietet die bta Workshops von
                  DozentInnen an, um Inhalte zu vertiefen oder spezialisieren.
                </p>
              </div>
            </Col>
          </Row>

          <Row>
            <Col>
              {loading ? (
                <Loader />
              ) : error ? (
                <Message variant='danger'> {error}</Message>
              ) : (
                <>
                  <Row xs={1} md={3} className='g-4'>
                    {trainings.map((training) => (
                      <EventOverview
                        key={training._id}
                        title={training.title}
                        date={training.date}
                        price={training.price}
                        shortdescription={training.shortdescription}
                        description={training.description}
                        trainer={training.trainer}
                        cce={training.cce}
                        time={training.time}
                        userpeergroup={training.userpeergroup}
                        usertraining={training.usertraining}
                        btapeergroup={training.btapeergroup}
                        btatraining={training.btatraining}
                        id={training._id}
                      />
                    ))}
                  </Row>
                </>
              )}
            </Col>
          </Row>
        </Col>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default WorkshopPage;
