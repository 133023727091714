import React, { Component, Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";
import BTAFamily from "../../asset/image/Familia.svg";

export class Description extends Component {
  render() {
    return (
      <Fragment>
        <Container className="text-center mt-4">
          <div className="bottomLine"></div>
          <Row>
            <Col lg={6} md={12} sm={12}>
              <img src={BTAFamily} alt="" />
            </Col>

            <Col
              lg={6}
              md={12}
              sm={12}
              className="d-flex justify-content-center align-items-center"
            >
              <p>
                „Die Zeit in der bta eine der wichtigsten in meinem Leben. In
                meinem Leben hat sich soviel geändert und bewegt und vor allem
                habe ich so viele interessante Menschen kennen gelernt. Gerne
                hätte ich einen Ort wo wir uns weiter gemeinsam austauschen und
                entwickeln können“ (Teilnehmerin aus dem Zert. Coach und
                Trainerkurs)
              </p>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default Description;
