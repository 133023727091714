import React, { Component } from "react";
import { Fragment } from "react";
import { Redirect } from "react-router";
import Footer from "../../components/Footer/Footer";
import PageTop from "../../components/Dashboard/PageTop/PageTop";
import TopNavigation from "../../components/TopNavigation/TopNavigation";
import { Container, Row, Col, Image } from "react-bootstrap";
import ProfileScreen from "../../components/Dashboard/ProfileScreen";

import LeftSide from "../../components/Dashboard/LeftSide";
import LeftLinks from "../../components/Dashboard/LeftLinks";

export class Dashboard extends Component {
  render() {
    if (!localStorage.getItem("userInfo")) {
      return <Redirect to="/login" />;
    }

    return (
      <Fragment>
        <TopNavigation title="Dashboard" />
        <PageTop pagetitle="Dashboard" />
        <Container>
          <Row className="py-3 ">
            <Col>
              <LeftSide />
              <LeftLinks />
            </Col>
            <Col xs={6}>
              <ProfileScreen />
            </Col>
            <Col>
              <a
                href="https://amzn.to/3raKeGq"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  border="0"
                  src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08KFYXMNG&Format=_SL300_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=boegeonlinema-21&language=de_DE"
                />
              </a>
              <Image
                src="https://ir-de.amazon-adsystem.com/e/ir?t=boegeonlinema-21&language=de_DE&l=li2&o=3&a=B08KFYXMNG"
                width="2"
                height="2"
                border="0"
                alt=""
                style={{ border: "none!important", margin: "0px!important" }}
              />
            </Col>
          </Row>
        </Container>

        <Footer />
      </Fragment>
    );
  }
}

export default Dashboard;
