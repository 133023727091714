import React, { Component, Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";

import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { NavLink } from "react-router-dom";

export class Footer extends Component {
  render() {
    return (
      <Fragment>
        <Container fluid={true} className="footerSection">
          <Row>
            <Col lg="3" md={6} sm={12} className="p-5 text-center">
              <h2 className="footerName text-center">Follow Us </h2>

              <div className="social-container">
                <a
                  className="facebook social"
                  href="https://www.facebook.com/Weiterbildung.Berlin"
                >
                  <FontAwesomeIcon icon={faFacebook} size="2x" />
                </a>
                <a
                  href="https://www.youtube.com/channel/UCbBCA6IJUN4VxI9URVEh4zA"
                  className="youtube social"
                >
                  <FontAwesomeIcon icon={faYoutube} size="2x" />
                </a>
                <a href="/" className="twitter social">
                  <FontAwesomeIcon icon={faTwitter} size="2x" />
                </a>
              </div>
            </Col>

            <Col lg="3" md={6} sm={12} className="p-5 text-justify">
              <h2 className="footerName text-center">Adresse </h2>
              <p className="footerDescription">
                BUSINESS TRENDS ACADEMY <br />
                Friedrichstraße 94 <br />
                10117 Berlin
                <br />
                <FontAwesomeIcon icon={faEnvelope} /> E-Mail :
                btaplanet@bta-weiterbildung.de
                <br></br>
                <FontAwesomeIcon icon={faPhone} /> Tel : 030 95 9999 100
                <br></br>
              </p>
            </Col>

            <Col lg="3" md={6} sm={12} className="p-5 text-justify">
              <h2 className="footerName text-center">Information </h2>
              <NavLink exact to="/bta" className="footerLink">
                Die BTA
              </NavLink>
              <br></br>
              <NavLink exact to="/kontakt" className="footerLink">
                Kontakt
              </NavLink>

              <br></br>
            </Col>

            <Col lg="3" md={6} sm={12} className="p-5 text-justify">
              <h2 className="footerName text-center">Datenschutz </h2>
              <NavLink exact to="/datenschutz" className="footerLink">
                Datenschutz
              </NavLink>
              <br></br>
            </Col>
          </Row>
        </Container>

        <Container fluid={true} className="text-center copyrightSection">
          <a className="copyrightlink" href="https://bta-weiterbildung.de">
            © Copyright 2022 Sascha Böge (BTA-Weiterbildung) All Rights Reserved
          </a>
        </Container>
      </Fragment>
    );
  }
}

export default Footer;
