import React from "react";
import { Card, ListGroup } from "react-bootstrap";
import { NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUsers } from "@fortawesome/free-solid-svg-icons";

import { faWrench } from "@fortawesome/free-solid-svg-icons";

const LeftLinks = () => {
  return (
    <Card className="ProfileCard mt-3">
      <Card.Body
        className="ProfileCardBody border-top"
        style={{
          border: "1px solid lightgrey",
          borderBottom: "0px solid #fff",
        }}
      >
        {" "}
        <h5>User</h5>
        <ListGroup variant="flush" className="mb-3">
          <ListGroup.Item>
            {" "}
            <NavLink
              to="/dashboard"
              className="link"
              activeStyle={{ color: "red" }}
            >
              <FontAwesomeIcon icon={faWrench} size="1x" />
              <b className="mx-2">Einstellung</b>
            </NavLink>
          </ListGroup.Item>
        </ListGroup>
        <h5>Einreichen</h5>
        <ListGroup variant="flush">
          {/*     <ListGroup.Item>
            {" "}
            <NavLink
              to="/cceuser"
              className="link"
              activeStyle={{ color: "red" }}
            >
              <FontAwesomeIcon icon={faFingerprint} size="1x" />
              <b className="mx-2">CCE anerkennen</b>
            </NavLink>
          </ListGroup.Item>

          
          <ListGroup.Item>
            {" "}
            <NavLink
              to="/bloguser"
              className="link"
              activeStyle={{ color: "red" }}
            >
              <FontAwesomeIcon icon={faBlog} size="1x" />
              <b className="mx-2">Blog einreichen</b>
            </NavLink>
          </ListGroup.Item>*/}

          <ListGroup.Item>
            {" "}
            <NavLink
              to="/traininguser"
              className="link"
              activeStyle={{ color: "red" }}
            >
              <FontAwesomeIcon icon={faUsers} size="1x" />

              <b className="mx-2">Peer Group / Training</b>
            </NavLink>
          </ListGroup.Item>
        </ListGroup>
      </Card.Body>

      <Card.Footer
        className="CardFooter  "
        style={{
          border: "1px solid lightgrey",
          borderTop: "0px solid #fff",
        }}
      ></Card.Footer>
    </Card>
  );
};

export default LeftLinks;
