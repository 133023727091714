import React, { Fragment, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../components/Footer/Footer";
import PageTop from "../components/PageTop/PageTop";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import CheckoutSteps from "../components/CheckoutSteps";
import { Container } from "react-bootstrap";
import { Form, Button, Row, Col } from "react-bootstrap";
import peergroup from "../asset/image/peergroup.svg";
import { saveShippingAddress } from "../actions/cartAction";

const ShippingPage = ({ history }) => {
  const cart = useSelector((state) => state.cart);
  const { shippingAddress } = cart;

  const [name, setName] = useState(shippingAddress.name);
  const [address, setAddress] = useState(shippingAddress.address);
  const [city, setCity] = useState(shippingAddress.city);
  const [postalCode, setPostalCode] = useState(shippingAddress.postalCode);

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(saveShippingAddress({ address, city, postalCode, name }));
    history.push("/payment");
  };
  return (
    <Fragment>
      <TopNavigation title="Login" />
      <PageTop pagetitle="Rechnungsadresse" />
      <Container>
        <CheckoutSteps step1 step2 />

        <Row>
          <Col md={6}>
            <Form onSubmit={submitHandler}>
              <Form.Group controlId="name">
                <Form.Label>Name</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Name"
                  value={name}
                  required
                  onChange={(e) => setName(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="address">
                <Form.Label>Rechnungsadresse</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Rechnungsadresse eingeben"
                  value={address}
                  required
                  onChange={(e) => setAddress(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="city">
                <Form.Label>Stadt</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Stadt"
                  value={city}
                  required
                  onChange={(e) => setCity(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Form.Group controlId="postalCode">
                <Form.Label>Postleitzahl</Form.Label>
                <Form.Control
                  type="text"
                  placeholder="Postleitzahl"
                  value={postalCode}
                  required
                  onChange={(e) => setPostalCode(e.target.value)}
                ></Form.Control>
              </Form.Group>

              <Button type="submit" variant="success" className="mt-2">
                Weiter zur Zahlung
              </Button>
            </Form>
          </Col>

          <Col md={6}>
            {" "}
            <img src={peergroup} alt="" height="350px" />
          </Col>
        </Row>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default ShippingPage;
