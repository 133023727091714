import React, { useState } from "react";
import { Form, Button, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import { createUserProduct } from "../../actions/peergroupAction";

function TrainingSubmit({ match, history }) {
  const [title, setTitle] = useState("");
  const [date, setDate] = useState(0);
  const [time, setTime] = useState("");
  const [zoompass, setZoompass] = useState("");
  const [zoomlink, setZoomlink] = useState("");
  const [shortdescription, setShortdescription] = useState("");
  const [description, setDescription] = useState("");
  const [userpeergroup, setUserpeergroup] = useState(false);
  const [usertraining, setUsertraining] = useState(false);

  const productCreate = useSelector((state) => state.productCreate);
  const { error, success } = productCreate;

  const dispatch = useDispatch();

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(
      createUserProduct({
        title,
        date,
        time,
        shortdescription,
        description,
        zoomlink,
        zoompass,
        userpeergroup,
        usertraining,
      })
    );
  };

  return (
    <div>
      {error && <Message variant="danger">{error}</Message>}
      {success && (
        <Message variant="success">
          Peer Group oder Training wurden erfolgreich eingereicht
        </Message>
      )}
      <Form onSubmit={submitHandler}>
        <Row className="p-2 mt-2">
          <Col className="p-0 " md={9} lg={9} sm={9} xs={9}>
            <Form.Group controlId="title">
              <Form.Label>Title</Form.Label>
              <Form.Control
                type="name"
                placeholder="Title"
                value={title}
                onChange={(e) => setTitle(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="date">
              <Form.Label>Datum</Form.Label>
              <Form.Control
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="time">
              <Form.Label>Uhrzeit</Form.Label>
              <Form.Control
                type="text"
                value={time}
                placeholder="von - bis"
                onChange={(e) => setTime(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="shortdescription">
              <Form.Label>Kurzbeschreibung</Form.Label>
              <Form.Control
                type="text"
                placeholder="Kurzbeschreibung ca. 100 Zeichen"
                as="textarea"
                value={shortdescription}
                onChange={(e) => setShortdescription(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="description">
              <Form.Label>Beschreibung</Form.Label>
              <Form.Control
                type="text"
                placeholder="Beschreibung"
                as="textarea"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="zoomlink">
              <Form.Label>Zoom Link</Form.Label>
              <Form.Control
                type="text"
                placeholder="Hier bitte den Zoomlink eintragen"
                value={zoomlink}
                onChange={(e) => setZoomlink(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="zoompass">
              <Form.Label>Zoompasswort</Form.Label>
              <Form.Control
                type="text"
                placeholder="Zoom Passwort"
                value={zoompass}
                onChange={(e) => setZoompass(e.target.value)}
              ></Form.Control>
            </Form.Group>

            <Form.Group controlId="userpeergroup" className="mt-3">
              <Form.Check
                type="checkbox"
                label="Peer Group"
                checked={userpeergroup}
                onChange={(e) => setUserpeergroup(e.target.checked)}
              ></Form.Check>
            </Form.Group>

            <Form.Group controlId="usertraining">
              <Form.Check
                type="checkbox"
                label="Training"
                checked={usertraining}
                onChange={(e) => setUsertraining(e.target.checked)}
              ></Form.Check>
            </Form.Group>
          </Col>
        </Row>
        <Button type="submit" variant="primary">
          Einreichen
        </Button>
      </Form>
    </div>
  );
}

export default TrainingSubmit;
