import React, { Component, Fragment } from "react";
import Footer from "../components/Footer/Footer";
import ForgetPassword from "../components/ForgetPasswordForm/ForgetPassword";
import PageTop from "../components/PageTop/PageTop";

import TopNavigation from "../components/TopNavigation/TopNavigation";

export class ForgetPasswordPage extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }
  render() {
    return (
      <Fragment>
        <TopNavigation title="Passwort vergessen" />
        <PageTop pagetitle="Passwort vergessen" />
        <ForgetPassword />
        <Footer />
      </Fragment>
    );
  }
}
export default ForgetPasswordPage;
