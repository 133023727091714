import React, { Component, Fragment } from "react";
import Footer from "../components/Footer/Footer";
import PageTop from "../components/PageTop/PageTop";
import RegisterForm from "../components/Register/RegisterForm";
import TopNavigation from "../components/TopNavigation/TopNavigation";

export class RegisterPage extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }
  render() {
    return (
      <Fragment>
        <TopNavigation title="Konto erstellen" />
        <PageTop pagetitle="Konto erstellen" />
        <RegisterForm />
        <Footer />
      </Fragment>
    );
  }
}

export default RegisterPage;
