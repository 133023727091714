import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Form, Button, Container, Col, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Message from "../../components/Message";
import Loader from "../../components/Loader";
import FormContainer from "../../components/FormContainer";
import { getUserDetails, updateUser } from "../../actions/userAction";
import { USER_UPDATE_RESET } from "../../constants/userConstans";
import Login from "../../asset/image/login.jpg";

const UserEdit = ({ match, history }) => {
  const userId = match.params.id;

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [qrcnumber, setQrcnumber] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [cce, setCce] = useState("");

  const dispatch = useDispatch();

  const userDetails = useSelector((state) => state.userDetails);
  const { loading, error, user } = userDetails;

  const userUpdate = useSelector((state) => state.userUpdate);
  const {
    loading: loadingUpdate,
    error: errorUpdate,
    success: successUpdate,
  } = userUpdate;

  useEffect(() => {
    if (successUpdate) {
      dispatch({ type: USER_UPDATE_RESET });
      history.push("/admin/userlist");
    } else {
      if (!user.name || user._id !== userId) {
        dispatch(getUserDetails(userId));
      } else {
        setName(user.name);
        setEmail(user.email);
        setCce(user.cce);
        setQrcnumber(user.qrcnumber);
        setIsAdmin(user.isAdmin);
      }
    }
  }, [dispatch, history, userId, user, successUpdate]);

  const submitHandler = (e) => {
    e.preventDefault();
    dispatch(updateUser({ _id: userId, name, email, cce, isAdmin, qrcnumber }));
  };

  return (
    <Container className="my-5">
      <Link to="/admin/userlist" className="btn btn-light my-3">
        Zurück
      </Link>

      <Row className="p-2 mt-5">
        <Col className="mt-2" md={6} lg={6} sm={6} xs={6}>
          <FormContainer>
            <h1>Edit User</h1>
            {loadingUpdate && <Loader />}
            {errorUpdate && <Message variant="danger">{errorUpdate}</Message>}
            {loading ? (
              <Loader />
            ) : error ? (
              <Message variant="danger">{error}</Message>
            ) : (
              <Form onSubmit={submitHandler}>
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="name"
                    placeholder="Enter name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="email">
                  <Form.Label>Email Address</Form.Label>
                  <Form.Control
                    type="email"
                    placeholder="Enter email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId="qrcnumber">
                  <Form.Label>QRC Mitgliedsnummer</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Mitgliedsnummer"
                    value={qrcnumber}
                    onChange={(e) => setQrcnumber(e.target.value)}
                  ></Form.Control>
                </Form.Group>
                <Form.Group controlId="cce">
                  <Form.Label>cce</Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="cce"
                    value={cce}
                    onChange={(e) => setCce(e.target.value)}
                  ></Form.Control>
                </Form.Group>

                <Form.Group controlId="isadmin">
                  <Form.Check
                    type="checkbox"
                    label="Is Admin"
                    checked={isAdmin}
                    onChange={(e) => setIsAdmin(e.target.checked)}
                  ></Form.Check>
                </Form.Group>

                <Button type="submit" variant="primary">
                  Update
                </Button>
              </Form>
            )}
          </FormContainer>
        </Col>
        <Col className="p-0 Desktop m-0" md={6} lg={6} sm={6} xs={6}>
          <img className="onboardBanner" src={Login} alt="" />
        </Col>
      </Row>
    </Container>
  );
};

export default UserEdit;
