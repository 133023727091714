import React, { Fragment, useEffect } from 'react';

import Footer from '../components/Footer/Footer';
import PageTop from '../components/PageTop/PageTop';
import TopNavigation from '../components/TopNavigation/TopNavigation';
import { Container, Row, Col, Button } from 'react-bootstrap';
import peergroup from '../asset/image/peergroup.svg';
import EventOverview from '../components/EventOverview/EventOverview';
import { useDispatch, useSelector } from 'react-redux';
import { listPeergroups } from '../actions/peergroupAction';
import Loader from '../components/Loader';
import Message from '../components/Message';
import qrclogo from '../asset/image/qrc_logo.jpeg';
import { Link } from 'react-router-dom';

const PeergroupPage = ({ match }) => {
  const dispatch = useDispatch();
  const pageNumber = match.params.pageNumber || 1;
  const peerGroupList = useSelector((state) => state.peerGroupList);
  const { loading, error, peergroups } = peerGroupList;

  useEffect(() => {
    dispatch(listPeergroups(pageNumber));
    window.scroll(0, 0);
  }, [dispatch, pageNumber]);

  return (
    <Fragment>
      <TopNavigation title='Peer Group' />
      <PageTop pagetitle='Peer Group' />
      <Container className='mt-5'>
        <div className='text-center mb-3'>
          <img
            src={peergroup}
            alt=''
            style={{
              width: '30vw',
            }}
            responsive
          />
        </div>
        <Row>
          <Col
            className='mt-2 text-center d-flex justify-content-center align-items-center '
            md={12}
            lg={6}
            sm={12}
            xs={12}
          >
            An diesem Ort auf dem btaplanet geht es rund um das Thema PEER
            GROUPS! Fallbesprechungen, Intervision, Supervision, Coaching- und
            Trainingsmethoden ausprobieren.
          </Col>

          <Col className='mt-2' md={12} lg={6} sm={12} xs={12}>
            <iframe
              src='https://www.youtube-nocookie.com/embed/tQZX4QcdcAA'
              width={'400px'}
              height={'200px'}
              title='YouTube video player'
              frameborder='0'
              allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
              allowfullscreen
            ></iframe>
          </Col>
        </Row>

        <Col className='mt-2' md={12} lg={12} sm={12} xs={12}>
          <Row className='mb-4'>
            <Col
              className='d-flex justify-content-center'
              md={12}
              lg={6}
              sm={12}
              xs={12}
            >
              <div className='serviceCard'>
                <h2 className='serviceName text-center'>Freie Peer Groups</h2>
                <p className='serviceDescription'>
                  An diesem Ort auf dem btaplanet könnt ihr eigene Online-PEER
                  GROUPS einrichten und euch in eigenen Gruppen treffen.
                </p>

                <Link
                  to='/traininguser'
                  className='btn btn-light my-3'
                  className='p-2 w-100 btn btn-info'
                  style={{ color: '#fff', fontWeight: 'bold' }}
                >
                  erstelle eine freie Peer Group
                </Link>
              </div>
            </Col>

            <Col
              className='d-flex justify-content-center'
              md={12}
              lg={6}
              sm={12}
              xs={12}
            >
              <div className='serviceCard'>
                <h2 className='serviceName text-center'>
                  QRC Peer Groups Berlin-Mitte mit Themen und Intervision
                </h2>
                <p
                  className='serviceDescription text-left '
                  style={{ float: 'left', width: '70%' }}
                >
                  Die QRC Peergroupleiterin Karin Angerer bietet jeden 3.
                  Dienstag im Monat QRC Peergroups an. Die Teilnehmenden
                  erhalten Weiterbildungspunkte (CCE-Punkte).
                </p>
                <img
                  src={qrclogo}
                  alt=''
                  height='150px'
                  style={{
                    float: 'right',
                    marginBottom: '10px',
                    borderRadius: '70px',
                    marginLeft: '10px',
                  }}
                />
              </div>
            </Col>
          </Row>
          {loading ? (
            <Loader />
          ) : error ? (
            <Message variant='danger'> {error}</Message>
          ) : (
            <>
              <Row xs={1} md={3} className='g-4'>
                {peergroups.map((peergroup) => (
                  <EventOverview
                    key={peergroup._id}
                    title={peergroup.title}
                    date={peergroup.date}
                    price={peergroup.price}
                    shortdescription={peergroup.shortdescription}
                    description={peergroup.description}
                    trainer={peergroup.trainer}
                    cce={peergroup.cce}
                    time={peergroup.time}
                    userpeergroup={peergroup.userpeergroup}
                    usertraining={peergroup.usertraining}
                    btapeergroup={peergroup.btapeergroup}
                    btatraining={peergroup.btatraining}
                    id={peergroup._id}
                  />
                ))}
              </Row>
            </>
          )}
        </Col>
      </Container>
      <Footer />
    </Fragment>
  );
};

export default PeergroupPage;
