import React, { Fragment } from "react";
import { Container } from "react-bootstrap";

const PageTopDashboard = (props) => {
  return (
    <Fragment>
      <Container className="topFixedPageDashboard p-0" fluid={true}>
        <div className="topPageOverlayDashboard"></div>
      </Container>
    </Fragment>
  );
};

export default PageTopDashboard;
