import React, { Component, Fragment } from "react";
import { Col, Container, Row } from "react-bootstrap";

export class TrennerPic extends Component {
  render() {
    return (
      <Fragment>
        <Container className="topFixedBanner p-0 mt-4" fluid={true}>
          <div className="topBannerOverlay">
            <Container className="topContent">
              <Row>
                <Col className="text-center">
                  <h1 className="topTitle">
                    Die Plattform von Coaches und für Coaches
                  </h1>
                  <h4 className="toSubTitle">
                    Coaching & Training für AbsolventInnen, TeilnehmerInnen,
                    DozentInnen, FreundInnen und die die es werden wollen
                  </h4>
                </Col>
              </Row>
            </Container>
          </div>
        </Container>
      </Fragment>
    );
  }
}

export default TrennerPic;
