import { useDispatch, useSelector } from "react-redux";
import { logout } from "../../actions/userAction";
import React, { useEffect, useState, Fragment } from "react";
import { Container, Nav, Navbar, NavDropdown, Row, Col } from "react-bootstrap";
import "../../asset/css/custom.css";
import whiteLogo from "../../asset/image/logo_planet_wi.svg";
import colorLogo from "../../asset/image/logo_planet_nom.svg";
import { NavLink, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { faTachometerAlt } from "@fortawesome/free-solid-svg-icons";
import { LinkContainer } from "react-router-bootstrap";

const TopNavigation = () => {
  const dispatch = useDispatch();

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const logoutHandler = () => {
    dispatch(logout());
  };

  const [navBarLogo, setNavBarLogo] = useState([whiteLogo]);
  const [navTheme, setNavTheme] = useState("dark");
  const [navBarBackground, setNavBarBackground] = useState("navBackground");
  const [navItem, setNavItem] = useState("navItem");
  // const [navBarTitle, setNavBarTitle] = useState("navTitle");
  const [navItemDropdown, setNavItemDropdown] = useState("navItemDropdown");

  useEffect(() => {
    if (typeof window !== "undefined") {
      window.addEventListener("scroll", () => {
        if (window.scrollY > 100) {
          setNavBarLogo([colorLogo]);
          setNavTheme("light");
          setNavItem("navItemScroll");
          setNavBarBackground("navBackgroundScroll");
          // setNavBarTitle("navTitleScroll");
          setNavItemDropdown("navItemDropdownScroll");
        } else if (window.scrollY < 100) {
          setNavBarLogo([whiteLogo]);
          setNavTheme("dark");
          setNavItem("navItem");
          setNavBarBackground("navBackground");
          //  setNavBarTitle("navTitle");
          setNavItemDropdown("navItemDropdown");
        }
      });
    }
  }, []);

  return (
    <Fragment>
      <title>BTA Planet </title>
      <Navbar
        collapseOnSelect
        fixed="top"
        expand="lg"
        bg="dark"
        variant={navTheme}
        className={navBarBackground}
      >
        <Container>
          <Navbar.Brand>
            <NavLink exact to="/">
              <img alt="" src={navBarLogo} className="logo" />
            </NavLink>
          </Navbar.Brand>

          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto"></Nav>
            <Nav>
              <div className="nav-link">
                <NavLink
                  activeStyle={{ color: "#ff0000" }}
                  exact
                  to="/peergroup"
                  className={navItem}
                >
                  Peer Groups
                </NavLink>
              </div>

              <div className="nav-link">
                <NavLink
                  activeStyle={{ color: "#ff0000" }}
                  exact
                  to="/trainings"
                  className={navItem}
                >
                  Trainings
                </NavLink>
              </div>
              <div className="nav-link">
                <NavLink
                  activeStyle={{ color: "#ff0000" }}
                  exact
                  to="/blogs"
                  className={navItem}
                >
                  Blog
                </NavLink>
              </div>
              <div className="nav-link">
                <NavLink
                  activeStyle={{ color: "#ff0000" }}
                  exact
                  to="/netiquette"
                  className={navItem}
                >
                  Netiquette
                </NavLink>
              </div>

              {userInfo ? (
                <NavDropdown title={userInfo.name} id="basic-nav-dropdown">
                  <div className="nav-link">
                    <Link className={navItemDropdown} to="/dashboard">
                      <Row>
                        <Col md={2}>
                          <FontAwesomeIcon icon={faTachometerAlt} size="1x" />
                        </Col>

                        <Col>Dashboard</Col>
                      </Row>
                    </Link>
                  </div>
                  <div className="nav-link">
                    <Link className={navItemDropdown} to="/cart">
                      <Row>
                        <Col md={2}>
                          <FontAwesomeIcon icon={faShoppingCart} size="1x" />
                        </Col>
                        <Col>Warenkorb</Col>
                      </Row>
                    </Link>
                  </div>
                  <NavDropdown.Divider />
                  <div className="nav-link">
                    <Link
                      to="/"
                      className={navItemDropdown}
                      onClick={logoutHandler}
                    >
                      <Row>
                        <Col md={2}>
                          <FontAwesomeIcon
                            icon={faUser}
                            size="1x"
                            color={"#f00"}
                          />
                        </Col>
                        <Col>Logout</Col>
                      </Row>
                    </Link>
                  </div>
                </NavDropdown>
              ) : (
                <div className="nav-link">
                  <NavLink
                    activeStyle={{ color: "#ff0000" }}
                    className={navItem}
                    to="/login"
                  >
                    <FontAwesomeIcon icon={faUser} size="1x" /> Login
                  </NavLink>
                </div>
              )}

              {userInfo && userInfo.isAdmin && (
                <NavDropdown title="Admin" id="adminmenu">
                  <LinkContainer to="/admin/userlist">
                    <NavDropdown.Item>Teilnehmer</NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to="/admin/bloglist">
                    <NavDropdown.Item>Blogs</NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to="/admin/produkte">
                    <NavDropdown.Item>PeerGrops / Trainings</NavDropdown.Item>
                  </LinkContainer>

                  <LinkContainer to="/admin/orderlist">
                    <NavDropdown.Item>Käufe</NavDropdown.Item>
                  </LinkContainer>
                </NavDropdown>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </Fragment>
  );
};

export default TopNavigation;
