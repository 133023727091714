import React, { Fragment, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import PageTop from "../../components/PageTop/PageTop";
import TopNavigation from "../../components/TopNavigation/TopNavigation";
import BlogList from "../../components/Admin/BlogList";
const BlogListScreen = ({ match, history }) => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <Fragment>
      <TopNavigation title="Blogs" />
      <PageTop pagetitle="Blogs" />
      <BlogList match={match} history={history} />
      <Footer />
    </Fragment>
  );
};

export default BlogListScreen;
