import React, { Component, Fragment } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import Forget from "../../asset/image/login.jpg";
import AppURL from "../../api/AppURL";
import axios from "axios";
import { toast } from "react-toastify";

class ForgetPassword extends Component {
  constructor() {
    super();
    this.state = {
      email: "",
      message: "",
    };
  }
  //forget   Form Submit

  formSubmit = (e) => {
    e.preventDefault();
    const data = {
      email: this.state.email,
    };
    axios
      .post(AppURL.UserForgetPassword, data)
      .then((response) => {
        this.setState({ message: response.data.message });

        toast.success(this.state.message, { position: "top-right" });
      })
      .catch((error) => {
        this.setState({ message: error.response.data.message });

        toast.error(this.state.message, { position: "top-right" });
      });
  };
  render() {
    return (
      <Fragment>
        <Container>
          <Row className="p-2 mt-5">
            <Col className="mt-2" md={12} lg={12} sm={12} xs={12}>
              <Row className="text-center">
                <Col
                  className="d-flex justify-content-center"
                  md={6}
                  lg={6}
                  sm={12}
                  xs={12}
                >
                  <Form className="onboardForm" onSubmit={this.formSubmit}>
                    <input
                      className="form-control m-2"
                      type="email"
                      placeholder="E-Mail"
                      onChange={(e) => {
                        this.setState({ email: e.target.value });
                      }}
                    />
                    <Button
                      className="btn btn-block m-2 site-btn-login"
                      type="submit"
                    >
                      {" "}
                      Reset Password{" "}
                    </Button>
                    <br></br> <br></br>
                    <hr />
                    <p>
                      {" "}
                      <b> Du hast bereits ein Konto ? </b>
                      <Link to="/login">
                        <b> Login </b>{" "}
                      </Link>{" "}
                    </p>
                    <p>
                      {" "}
                      <b>Du hast noch kein Konto? </b>
                      <Link to="/register">
                        <b> Anmelden </b>{" "}
                      </Link>{" "}
                    </p>
                  </Form>
                </Col>

                <Col className="p-0 Desktop m-0" md={6} lg={6} sm={6} xs={6}>
                  <img className="onboardBanner" src={Forget} alt="" />
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </Fragment>
    );
  }
}

export default ForgetPassword;
