import React, { Fragment, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import PageTop from "../../components/PageTop/PageTop";
import UserEdit from "../../components/Admin/UserEdit";
import TopNavigation from "../../components/TopNavigation/TopNavigation";

const UserEditScreen = ({ match, history }) => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <Fragment>
      <TopNavigation title="User bearbeiten" />
      <PageTop pagetitle="User bearbeiten" />
      <UserEdit match={match} history={history} />
      <Footer />
    </Fragment>
  );
};

export default UserEditScreen;
