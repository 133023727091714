import React, { Component, Fragment } from "react";
import Footer from "../components/Footer/Footer";
import PageTop from "../components/PageTop/PageTop";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import { Container, Row, Col } from "react-bootstrap";
import Netiquette from "../asset/image/netikette.svg";

export class NetiquettePage extends Component {
  componentDidMount() {
    window.scroll(0, 0);
  }
  render() {
    return (
      <Fragment>
        <TopNavigation title="Netiquette" />
        <PageTop pagetitle="Netiquette" />
        <Container className="mt-5">
          <Col className="mt-2 " md={12} lg={12} sm={12} xs={12}>
            <div className="text-center">
              <img
                src={Netiquette}
                alt=""
                style={{
                  width: "30vw",
                }}
                responsive
                className="mb-2"
              />
            </div>
            <Row className="">
              <Col
                className="d-flex justify-content-center"
                md={6}
                lg={3}
                sm={12}
                xs={12}
              >
                <div className="serviceCard">
                  <h2 className="serviceName text-center">Höflich bleiben</h2>
                  <p className="serviceDescription">
                    Verzichten Sie auf Beleidigungen, Verunglimpfungen,
                    Provokationen Drohungen gegenüber anderen. Bedenken Sie,
                    dass Beleidigungen strafrechtlich verfolgt werden können.
                    Respektieren Sie die Meinung anderer und äußern Sie
                    konstruktive Kritik.
                  </p>
                </div>
              </Col>
              <Col
                className="d-flex justify-content-center"
                md={6}
                lg={3}
                sm={12}
                xs={12}
              >
                <div className="serviceCard">
                  <h2 className="serviceName text-center">
                    Auf die Lesbarkeit achten
                  </h2>
                  <p className="serviceDescription">
                    Schreiben Sie mit korrektem Satzbau, korrekter
                    Rechtschreibung und nutzen Sie die Zeichensetzung. Verwenden
                    Sie Groß- und Kleinschreibung und verzichten Sie auf zu
                    viele farbliche Markierungen oder andere Formatierungen.
                  </p>
                </div>
              </Col>
              <Col
                className="d-flex justify-content-center"
                md={6}
                lg={3}
                sm={12}
                xs={12}
              >
                <div className="serviceCard">
                  <h2 className="serviceName text-center">Duzen ist üblich</h2>
                  <p className="serviceDescription">
                    Im Internet wird häufiger geduzt als gesiezt. Ärgern Sie
                    sich nicht, wenn Sie geduzt werden – selbst wenn Sie Ihre
                    Gesprächspartner siezen.
                  </p>
                </div>
              </Col>
              <Col
                className="d-flex justify-content-center"
                md={6}
                lg={3}
                sm={12}
                xs={12}
              >
                <div className="serviceCard">
                  <h2 className="serviceName text-center">
                    Den Klarnamen nutzen
                  </h2>
                  <p className="serviceDescription">
                    Nutzen Sie wenn möglich Ihren Klarnamen. Die Anonymität
                    verleitet zu Äußerungen, die Sie face-to-face eher nicht
                    machen würden.
                  </p>
                </div>
              </Col>
              <Col
                className="d-flex justify-content-center"
                md={6}
                lg={3}
                sm={12}
                xs={12}
              >
                <div className="serviceCard">
                  <h2 className="serviceName text-center">
                    Missverständnisse vermeiden
                  </h2>
                  <p className="serviceDescription">
                    Überlegen Sie gut, was Sie mit Ihrer Äußerung sagen wollen.
                    Äußern Sie sich dann eindeutig oder nutzen Sie als Ersatz
                    für die fehlende Gestik und Mimik Emoticons, zum Beispiel
                    Smileys. Dies gilt insbesondere bei ironischen Bemerkungen.
                  </p>
                </div>
              </Col>

              <Col
                className="d-flex justify-content-center"
                md={6}
                lg={3}
                sm={12}
                xs={12}
              >
                <div className="serviceCard">
                  <h2 className="serviceName text-center">
                    Netz-Jargon beachten
                  </h2>
                  <p className="serviceDescription">
                    Die Netzgemeinde hat ihre eigene Sprache entwickelt und
                    entwickelt diese immer weiter. Machen Sie sich also mit
                    gängigen Abkürzungen vertraut, wie „LOL“ (Laughing Out Loud)
                    oder „OMG“ (Oh my God)
                  </p>
                </div>
              </Col>

              <Col
                className="d-flex justify-content-center"
                md={6}
                lg={3}
                sm={12}
                xs={12}
              >
                <div className="serviceCard">
                  <h2 className="serviceName text-center">Richtig zitieren</h2>
                  <p className="serviceDescription">
                    Kennzeichnen Sie das Zitat und verweisen Sie entsprechend
                    dem Urheberrecht auf den Urheber. Zitieren Sie nicht zu
                    viel, sondern nur den relevanten Inhalt.{" "}
                  </p>
                </div>
              </Col>

              <Col
                className="d-flex justify-content-center"
                md={6}
                lg={3}
                sm={12}
                xs={12}
              >
                <div className="serviceCard">
                  <h2 className="serviceName text-center">
                    Fotos und Videos nicht bedenkenlos verbreiten
                  </h2>
                  <p className="serviceDescription">
                    Achten Sie das Urheberrecht und das Recht am eigenen Bild –
                    nutzen Sie keine fremden Fotos ohne Einverständnis.
                    Vermeiden Sie Bilder, die Gewalt darstellen, andere Menschen
                    entwürdigen.{" "}
                  </p>
                </div>
              </Col>
            </Row>
          </Col>
        </Container>
        <Footer />
      </Fragment>
    );
  }
}

export default NetiquettePage;
