import React, { useEffect } from "react";
import Loader from "../Loader";
import Message from "../Message";
import { useDispatch, useSelector } from "react-redux";
import { Table, Button, Container, Row, Col } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import {
  listBlogsAdmin,
  deleteBlog,
  createBlog,
} from "../../actions/blogAction";
import { BLOG_CREATE_RESET } from "../../constants/blogConstans";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faTrash } from "@fortawesome/free-solid-svg-icons";

const BlogList = ({ history, match }) => {
  const dispatch = useDispatch();

  const blogListAdmin = useSelector((state) => state.blogListAdmin);
  const { loading, error, blogs } = blogListAdmin;

  const blogDelete = useSelector((state) => state.blogDelete);
  const {
    loading: loadingDelete,
    error: errorDelete,
    success: successDelete,
  } = blogDelete;

  const blogCreate = useSelector((state) => state.blogCreate);
  const {
    loading: loadingCreate,
    error: errorCreate,
    success: successCreate,
    blog: createdBlog,
  } = blogCreate;

  const userLogin = useSelector((state) => state.userLogin);
  const { userInfo } = userLogin;

  const createBlogHandler = () => {
    dispatch(createBlog());
  };

  useEffect(() => {
    dispatch({ type: BLOG_CREATE_RESET });

    if (!userInfo.isAdmin) {
      history.push("/login");
    }
    if (successCreate) {
      history.push(`/admin/blog/${createdBlog._id}/edit`);
    } else {
      dispatch(listBlogsAdmin());
    }
  }, [dispatch, history, userInfo, successDelete, successCreate, createdBlog]);

  const deleteHandler = (id) => {
    if (window.confirm("Sicher?")) {
      dispatch(deleteBlog(id));
    }
  };

  return (
    <Container className=" my-5">
      <Row>
        <Col className="text-right">
          <Button className="my-3" onClick={createBlogHandler}>
            <FontAwesomeIcon
              icon={faPlus}
              size="1x"
              style={{ color: "#fff" }}
            />{" "}
            Erstelle Blog
          </Button>
        </Col>
      </Row>

      {loadingDelete && <Loader />}
      {errorDelete && <Message variant="danger">{errorDelete}</Message>}
      {loadingCreate && <Loader />}
      {errorCreate && <Message variant="danger">{errorCreate}</Message>}
      {loading ? (
        <Loader />
      ) : error ? (
        <Message variant="danger">{error}</Message>
      ) : (
        <Table striped bordered hover responsive className="table-sm">
          <thead>
            <tr>
              <th>Name</th>
              <th>Datum</th>
              <th>Ersteller</th>

              <th>Online</th>
            </tr>
          </thead>

          <tbody>
            {blogs.map((blog) => (
              <tr key={blog._id}>
                <td>{blog.name}</td>
                <td>
                  {new Date(
                    blog.createdAt.substring(0, 10)
                  ).toLocaleDateString()}

                  <br />
                  {blog.time}
                </td>

                <td>{blog.user.name}</td>

                <td style={{ textAlign: "center" }}>
                  {!blog.hidden ? (
                    <FontAwesomeIcon
                      icon={faCheck}
                      size="1x"
                      style={{ color: "green" }}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={faTimes}
                      size="1x"
                      style={{ color: "red" }}
                    />
                  )}
                </td>
                <td>
                  <LinkContainer to={`/admin/blog/${blog._id}/edit`}>
                    <Button variant="light" className="btn-sm">
                      <FontAwesomeIcon
                        icon={faEdit}
                        size="1x"
                        style={{ color: "green" }}
                      />
                    </Button>
                  </LinkContainer>
                  <Button
                    variant="danger"
                    className="btn-sm"
                    onClick={() => deleteHandler(blog._id)}
                  >
                    <FontAwesomeIcon
                      icon={faTrash}
                      size="1x"
                      style={{ color: "#fff" }}
                    />
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      )}
    </Container>
  );
};

export default BlogList;
