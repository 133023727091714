import React, { Fragment, useEffect } from "react";
import Footer from "../../components/Footer/Footer";
import PageTop from "../../components/PageTop/PageTop";
import UserList from "../../components/Admin/UserList";
import TopNavigation from "../../components/TopNavigation/TopNavigation";

const UserListScreen = () => {
  useEffect(() => {
    window.scroll(0, 0);
  }, []);
  return (
    <Fragment>
      <TopNavigation title="Teilnehmer Liste" />
      <PageTop pagetitle="Teilnehmer Liste" />
      <UserList />
      <Footer />
    </Fragment>
  );
};

export default UserListScreen;
