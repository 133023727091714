import React, { Fragment, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Footer from "../components/Footer/Footer";
import PageTop from "../components/PageTop/PageTop";
import TopNavigation from "../components/TopNavigation/TopNavigation";
import { Col, Container, Row } from "react-bootstrap";
import { listBlogs } from "../actions/blogAction";
import { Route } from "react-router-dom";
import Blog from "../components/Blog/Blog";
import Loader from "../components/Loader";
import Message from "../components/Message";
import SearchBox from "../components/SearchBox";
import Paginate from "../components/Paginate";

const BlogsPage = ({ match }) => {
  const dispatch = useDispatch();
  const keyword = match.params.keyword;
  const pageNumber = match.params.pageNumber || 1;

  const blogList = useSelector((state) => state.blogList);
  const { loading, error, blogs, page, pages } = blogList;
  useEffect(() => {
    dispatch(listBlogs(keyword, pageNumber));

    window.scroll(0, 0);
  }, [dispatch, keyword, pageNumber]);

  return (
    <Fragment>
      <TopNavigation title="Blog" />
      <PageTop
        pagetitle="Blog"
        pagesubtitle="Du möchtest auch einen Blogartikel veröffentlichen? Dann melde dich schnell bei uns an."
      />
      <Container className="mt-5">
        <Col md={9} lg={4} sm={12} xs={12}>
          <Route render={({ history }) => <SearchBox history={history} />} />
        </Col>
        {loading ? (
          <Loader />
        ) : error ? (
          <Message variant="danger"> {error}</Message>
        ) : (
          <>
            <Row xs={1} md={3} className="g-4">
              {blogs.map((blog) => (
                <Blog blog={blog} key={blog._id} />
              ))}
            </Row>
            <Paginate
              pages={pages}
              page={page}
              keyword={keyword ? keyword : ""}
            />
          </>
        )}
      </Container>
      <Footer />
    </Fragment>
  );
};

export default BlogsPage;
