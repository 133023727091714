import React, { Component } from "react";
import { Fragment } from "react";
import { Redirect } from "react-router";
import Footer from "../../components/Footer/Footer";
import PageTop from "../../components/Dashboard/PageTop/PageTop";
import TopNavigation from "../../components/TopNavigation/TopNavigation";
import { Container, Row, Col, Image } from "react-bootstrap";

import TrainingSubmit from "../../components/Dashboard/TrainingSubmit";

import LeftSide from "../../components/Dashboard/LeftSide";
import LeftLinks from "../../components/Dashboard/LeftLinks";

export class TrainingUser extends Component {
  render() {
    if (!localStorage.getItem("userInfo")) {
      return <Redirect to="/login" />;
    }

    return (
      <Fragment>
        <TopNavigation title="Training / Peer Group einreichen" />
        <PageTop pagetitle="Training / Peer Group einreichen" />
        <Container>
          <Row className="py-3 ">
            <Col>
              <LeftSide />
              <LeftLinks />
            </Col>
            <Col xs={6}>
              <h3>Training / Peer Group einreichen</h3>
              <TrainingSubmit />
            </Col>
            <Col>
              <a
                href="https://www.amazon.de/Basiswissen-Systemisches-Coaching-L%C3%B6sungsorientierte-Coaching-Methoden/dp/B08KFYXMNG?__mk_de_DE=%C3%85M%C3%85%C5%BD%C3%95%C3%91&crid=97JZ0HZUKR52&keywords=coaching+buch&qid=1639465556&sprefix=coaching%2Caps%2C261&sr=8-2-spons&psc=1&spLa=ZW5jcnlwdGVkUXVhbGlmaWVyPUExSTJORE9GS0JNV1hMJmVuY3J5cHRlZElkPUEwMjY0NzU2M1VNNDM0VFlDUlAwViZlbmNyeXB0ZWRBZElkPUEwMTgxMDE3MlE0N0w2S0hIOEVFRyZ3aWRnZXROYW1lPXNwX2F0ZiZhY3Rpb249Y2xpY2tSZWRpcmVjdCZkb05vdExvZ0NsaWNrPXRydWU%3D&linkCode=li2&tag=boegeonlinema-21&linkId=d7103424469b7e70fe449402468aabfc&language=de_DE&ref_=as_li_ss_il"
                target="_blank"
                rel="noreferrer"
              >
                <Image
                  border="0"
                  src="//ws-eu.amazon-adsystem.com/widgets/q?_encoding=UTF8&ASIN=B08KFYXMNG&Format=_SL300_&ID=AsinImage&MarketPlace=DE&ServiceVersion=20070822&WS=1&tag=boegeonlinema-21&language=de_DE"
                />
              </a>
              <Image
                src="https://ir-de.amazon-adsystem.com/e/ir?t=boegeonlinema-21&language=de_DE&l=li2&o=3&a=B08KFYXMNG"
                width="2"
                height="2"
                border="0"
                alt=""
                style={{ border: "none!important", margin: "0px!important" }}
              />
            </Col>
          </Row>
        </Container>

        <Footer />
      </Fragment>
    );
  }
}

export default TrainingUser;
